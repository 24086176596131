import { UpdateEventRequestBody } from "@ero/app-common/v2/routes/models/event";
import {
  AddJobRequestBody,
  JobTelemetryUpdateRequest,
  UpdateJobRequestBody,
} from "@ero/app-common/v2/routes/models/job";
import { UpdateOrderRequestBody } from "@ero/app-common/v2/routes/models/order";
import { createAction } from "@reduxjs/toolkit";

const getOrder = createAction<number>("orderDetailsSaga/get-order");

const updateOrder = createAction<UpdateOrderRequestBody>(
  "orderDetailsSaga/update-order",
);

const addJob = createAction<AddJobRequestBody>("orderDetailsSaga/add-job");

const updateJob = createAction<{
  id: number;
  update: UpdateJobRequestBody;
}>("orderDetailsSaga/update-job");

const deleteJob = createAction<number>("orderDetailsSaga/delete-job");

const getJobTelemtry = createAction<number>(
  "orderDetailsSaga/get-job-telemetry",
);

const updateJobTelemetry = createAction<{
  id: number;
  update: JobTelemetryUpdateRequest;
}>("orderDetailsSaga/update-job-telemetry");

const updateEvent = createAction<{
  id: number;
  update: UpdateEventRequestBody;
}>("orderDetailsSaga/update-event");

const deleteEvent = createAction<{
  id: number;
}>("orderDetailsSaga/delete-event");

const downloadDeliveryNote = createAction(
  "orderDetailsSaga/download-delivery-note",
);

export const sagaActions = {
  getOrder,
  updateOrder,
  addJob,
  updateJob,
  deleteJob,
  getJobTelemtry,
  updateJobTelemetry,
  updateEvent,
  deleteEvent,
  downloadDeliveryNote,
};
