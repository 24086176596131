import { OptionType } from "Types";

export const sortByLabel = (a: OptionType, b: OptionType) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};
