import {
  JobResponseBody,
  JobTelemetryResponseBody,
} from "@ero/app-common/v2/routes/models/job";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type OrderDetailsStateEvents = {
  _id: number;
  start: number;
  end: number;
  jobs: JobResponseBody[];
  locked: boolean;
}[];

type OrderDetailsState = {
  orderLoading: boolean;
  jobLoading: boolean;
  jobTelemetryLoading: boolean;
  jobTelemetrySuccess: boolean;
  order: OrderResponseBody | undefined;
  events: OrderDetailsStateEvents;
  plannedJobs: JobResponseBody[];
  unplannedJobs: JobResponseBody[];
  jobTelemetry: JobTelemetryResponseBody;
};

const initialState: OrderDetailsState = {
  orderLoading: false,
  jobLoading: false,
  jobTelemetryLoading: false,
  jobTelemetrySuccess: false,
  order: undefined,
  events: [],
  plannedJobs: [],
  unplannedJobs: [],
  jobTelemetry: {} as JobTelemetryResponseBody,
};

type SetLoadingAction = PayloadAction<boolean>;
type SetOrderAction = PayloadAction<{
  order: OrderResponseBody;
  events: OrderDetailsStateEvents;
  plannedJobs: JobResponseBody[];
  unplannedJobs: JobResponseBody[];
}>;
type SetJobTelemetryAction = PayloadAction<JobTelemetryResponseBody>;

export const orderDetailsSlice = createSlice({
  name: "orderDetails",
  initialState,
  reducers: {
    setOrderLoading: (state, action: SetLoadingAction) => {
      state.orderLoading = action.payload;
    },
    setJobLoading: (state, action: SetLoadingAction) => {
      state.jobLoading = action.payload;
    },
    setJobTelemetryLoading: (state, action: SetLoadingAction) => {
      state.jobTelemetryLoading = action.payload;
      state.jobTelemetrySuccess = !action.payload;
    },
    setOrder: (state, action: SetOrderAction) => {
      state.order = action.payload.order;
      state.events = action.payload.events;
      state.plannedJobs = action.payload.plannedJobs;
      state.unplannedJobs = action.payload.unplannedJobs;
    },
    setJobTelemetry: (state, action: SetJobTelemetryAction) => {
      state.jobTelemetry = action.payload;
    },
  },
});
