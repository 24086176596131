import { Add } from "@mui/icons-material";
import { Card, Fab } from "@mui/material";
import React from "react";
import { JobsList } from "../jobsList";

interface IJobsCard {
  onAddBtnClick: () => void;
}

export const JobsCard: React.FC<IJobsCard> = ({ onAddBtnClick }) => {
  return (
    <Card sx={{ height: "100%", position: "relative" }}>
      <JobsList />
      <Fab
        color="primary"
        sx={{ position: "absolute", right: 24, bottom: 8 }}
        onClick={onAddBtnClick}
      >
        <Add />
      </Fab>
    </Card>
  );
};
