import { useDndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { alpha, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SORTABLE_CONTEXT } from "./jobsList";
import { SortableItem } from "./sortableItem";

export const UnplanContext: React.FC = () => {
  const [t] = useTranslation();

  const { active, over } = useDndContext();

  const isCurrentlyDraggedJobUnplanned = useMemo(
    () =>
      active?.data.current?.sortable.containerId === SORTABLE_CONTEXT.UNPLANNED,
    [active?.data],
  );

  const showDropZone = useMemo(
    () => over && !isCurrentlyDraggedJobUnplanned,
    [over, isCurrentlyDraggedJobUnplanned],
  );

  if (!showDropZone) return <></>;

  return (
    <SortableContext id={SORTABLE_CONTEXT.UNPLANNED} items={[]}>
      <Typography
        sx={(theme) => ({
          borderStyle: "dashed",
          borderColor: alpha(theme.palette.primary.light, 0.6),
          p: 2,
        })}
      >
        {t("orders.details.overview.dropZone")}
      </Typography>
      <SortableItem
        contextId={SORTABLE_CONTEXT.UNPLANNED}
        isDraggable={false}
      />
    </SortableContext>
  );
};
