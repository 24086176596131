import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/event";
import {
  AddEventRequestBody,
  EventResponseBody,
  EventsRequestQuery,
  EventsResponseBody,
  UpdateEventRequestBody,
} from "@ero/app-common/v2/routes/models/event";
import { apiInstanceV2 } from "App/axios";

export const getEvents = async (params: EventsRequestQuery) =>
  (
    await apiInstanceV2.get<EventsResponseBody>(baseUrl + SubUrls.ROOT, {
      params,
    })
  ).data;

export const addEvent = async (event: AddEventRequestBody) =>
  (await apiInstanceV2.post<EventsResponseBody>(baseUrl + SubUrls.ROOT, event))
    .data;

export const updateEvent = async (id: number, update: UpdateEventRequestBody) =>
  (
    await apiInstanceV2.patch<EventResponseBody>(
      baseUrl + SubUrls.BY_ID.replace(":id", `${id}`),
      update,
    )
  ).data;

export const deleteEvent = async (id: number) =>
  (
    await apiInstanceV2.delete<EventsResponseBody>(
      baseUrl + SubUrls.BY_ID.replace(":id", `${id}`),
    )
  ).data;
