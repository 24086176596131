import { PERM } from "@ero/app-common/enums";
import { LANGUAGE } from "@ero/app-common/enums/language";
import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import { useUserTitle } from "Hooks/userTitle";
import { createEmployee, updateEmployee } from "Store/employees/actions";
import { type LocalEmployeeType } from "Store/employees/specs";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getValidationSchema } from "./formConfig";
import { BasicData } from "./screens";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  employee?: LocalEmployeeType;
}

export const EmployeeModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  employee,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const editMode = useMemo(() => !!employee?._id, [employee?._id]);

  const initialValues = useMemo(
    () => ({
      avatar: "",
      userName: "",
      email: "",
      password: "",
      permission: PERM.DRIVER,
      language: LANGUAGE.DE,
      firstName: "",
      lastName: "",
      phone: "",
      notes: "",
      ...employee,
      manualPasswordSelection: editMode,
    }),
    [employee, editMode],
  );

  const onSubmit = useCallback(
    (values) => {
      const { avatar, ...rest } = values;
      const update = rest;
      if (avatar && avatar.indexOf("data:image") === 0) {
        update.avatar = avatar;
      }
      if (!!employee && !!employee._id) {
        dispatch(updateEmployee(update, employee._id));
      } else {
        dispatch(createEmployee(update));
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [employee],
  );

  const employeeName = useUserTitle(employee);

  const validationSchema = useMemo(
    () => getValidationSchema(editMode),
    [editMode],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      scroll="body"
    >
      <DialogTitle>
        {employee?._id ? employeeName : t("employees.create")}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isValid, dirty }) => {
          return (
            <Form>
              <DialogContent dividers>
                <BasicData editMode={editMode} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  disabled={!isValid || !dirty}
                  type="submit"
                  startIcon={<Save />}
                >
                  {t("general.buttons.save")}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
