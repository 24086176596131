import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { DeleteOutline, DragHandle } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { forwardRef, default as React, useMemo } from "react";
import { useJobIndex } from "Screens/order-details/hooks/useJobIndex";
import { getJobStatusColor } from "Utils";

type JobItemProps = {
  job: JobResponseBody;
  isDraggable?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: number,
  ) => void;
  onDelete?: (jobId: number) => void;
  style:
    | {
        transform: string;
        transition: string | undefined;
      }
    | undefined;
};

export const JobItem = forwardRef<HTMLDivElement, JobItemProps>(
  ({ job, isDraggable = false, onClick, onDelete, style, ...props }, ref) => {
    const index = useJobIndex(job._id);
    const jobStatusColor = useMemo(
      () => getJobStatusColor(job.status).split(".")[1] ?? "default",
      [job.status],
    );

    return (
      <Paper
        ref={ref}
        elevation={2}
        square
        style={style}
        sx={(theme) => ({
          borderLeft: `5px solid ${theme.palette.status[jobStatusColor]}`,
        })}
      >
        <ListItem
          disablePadding
          secondaryAction={
            <>
              {isDraggable && (
                <IconButton disableRipple>
                  <DragHandle
                    {...props}
                    sx={{
                      cursor: "grab",
                      outline: "none",
                      "&:active": {
                        cursor: "grabbing",
                      },
                    }}
                  />
                </IconButton>
              )}
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onDelete && onDelete(job._id)}
              >
                <DeleteOutline />
              </IconButton>
            </>
          }
        >
          <ListItemButton
            onClick={(event) => onClick && onClick(event, job._id)}
          >
            <ListItemIcon sx={{ minWidth: 25 }}>{index}</ListItemIcon>
            <ListItemText
              primary={job.name}
              secondary={job.parcel.name}
              sx={{ pr: 3 }}
            />
          </ListItemButton>
        </ListItem>
      </Paper>
    );
  },
);

JobItem.displayName = "JobItem";
