import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/company";
import { apiInstanceV2 } from "App/axios";

export const getDropDownOptionsV2 = async (
  key: string,
  accessToken?: string,
) => {
  const config = {};
  if (accessToken) {
    config["headers"] = { Authorization: `Bearer ${accessToken}` };
  }

  return await apiInstanceV2.get(
    baseUrl + SubUrls.DROPDOWN.replace(":id", "self") + "?key=" + key,
    config,
  );
};
