import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";

export const useJobIndex = (id: number) => {
  const { events, plannedJobs, unplannedJobs } = useSelector(
    (state: AppState) => state.orderDetails,
  );

  const jobsSequence = useMemo(
    () => [
      ...events.map((event) => event.jobs).flat(),
      ...plannedJobs,
      ...unplannedJobs,
    ],
    [events, plannedJobs, unplannedJobs],
  );

  const index = useMemo(
    () => jobsSequence.findIndex((job) => job._id === id) + 1,
    [id, jobsSequence],
  );

  return index;
};
