import { Marker, Polyline } from "@react-google-maps/api";
import React, { useCallback, useMemo, useRef } from "react";
import { useParcelEditingContext } from "../../../contexts/parcelEditingContext";
import { lineSymbol, POLYGON_TRANSFORMATION_MODE } from "../../../utils";

const lngOffset = 0.0006;

export const SplitLine: React.FC = () => {
  const lineCoords = useRef<google.maps.LatLng[] | never[]>([]);

  const { mapRef, transformationMode, splitLineRef } =
    useParcelEditingContext();

  const onLoad = useCallback(
    (polyline: google.maps.Polyline) => {
      splitLineRef.current = polyline;
    },
    [splitLineRef],
  );

  const isSplitMode = useMemo(
    () => transformationMode === POLYGON_TRANSFORMATION_MODE.SPLIT,
    [transformationMode],
  );

  const initalPath: google.maps.LatLng[] | never[] = useMemo(() => {
    if (!isSplitMode) {
      lineCoords.current = [];
      return [];
    }

    const boundsCenter = mapRef?.current?.getBounds()?.getCenter();
    if (!boundsCenter) return [];

    const startPoint = new google.maps.LatLng({
      lat: boundsCenter?.lat(),
      lng: boundsCenter.lng() - lngOffset,
    });

    const endPoint = new google.maps.LatLng({
      lat: boundsCenter?.lat(),
      lng: boundsCenter.lng() + lngOffset,
    });

    lineCoords.current = [startPoint, endPoint];
    return [startPoint, endPoint];
  }, [isSplitMode, mapRef]);

  const handleMarkerDrag = useCallback(
    (e: google.maps.MapMouseEvent, index: number) => {
      const coord = e.latLng;
      if (coord) {
        const path = lineCoords.current;
        path![index] = coord;
        splitLineRef.current?.setPath(path!);
        lineCoords.current = path;
      }
    },
    [splitLineRef],
  );

  return (
    <>
      {lineCoords.current.map((coord: google.maps.LatLng, index: number) => (
        <Marker
          key={index}
          position={coord}
          draggable
          onDrag={(e) => handleMarkerDrag(e, index)}
        />
      ))}
      <Polyline
        onLoad={onLoad}
        path={initalPath}
        options={{
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "20px",
            },
          ],
        }}
      />
    </>
  );
};
