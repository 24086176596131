import {
  createJobV2,
  deleteJobV2,
  downloadDeliveryNote,
  getJobTelemetryV2,
  getOrderById,
  updateJobTelemetryV2,
  updateJobV2,
  updateOrder,
} from "Api";
import { deleteEvent, updateEvent } from "Api/events";
import { errorToast } from "Services";
import { downloadFile, getPartialOrder } from "Utils";
import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { sagaActions } from "./orderDetailsSagaActions";
import { orderDetailsSlice } from "./orderDetailsSlice";

function* fetchOrder(id: number) {
  const { data } = yield call(getOrderById, id);
  const { events, plannedJobs, unplannedJobs } = getPartialOrder(data);

  yield put(
    orderDetailsSlice.actions.setOrder({
      order: data,
      events,
      plannedJobs,
      unplannedJobs,
    }),
  );
}

function* getOrderSaga(action: ReturnType<typeof sagaActions.getOrder>) {
  try {
    yield put(orderDetailsSlice.actions.setOrderLoading(true));
    yield call(fetchOrder, action.payload);
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadOrder"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setOrderLoading(false));
  }
}

function* updateOrderSaga(action: ReturnType<typeof sagaActions.updateOrder>) {
  try {
    yield put(orderDetailsSlice.actions.setOrderLoading(true));
    const store = yield select();
    const orderId = store.orderDetails.order._id;

    const { data } = yield call(updateOrder, orderId, action.payload);
    const { events, plannedJobs, unplannedJobs } = getPartialOrder(data);

    yield put(
      orderDetailsSlice.actions.setOrder({
        order: data,
        events,
        plannedJobs,
        unplannedJobs,
      }),
    );
  } catch (error) {
    errorToast(i18n.t("errors.unableToUpdateOrder"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setOrderLoading(false));
  }
}

function* addJobSaga(action: ReturnType<typeof sagaActions.addJob>) {
  try {
    yield put(orderDetailsSlice.actions.setOrderLoading(true));

    const store = yield select();
    const order = store.orderDetails.order;

    yield call(createJobV2, action.payload);
    yield call(fetchOrder, order._id);
  } catch (error) {
    errorToast(i18n.t("errors.unableToAddJob"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setOrderLoading(false));
  }
}

function* deleteJobSaga(action: ReturnType<typeof sagaActions.deleteJob>) {
  try {
    yield put(orderDetailsSlice.actions.setOrderLoading(true));

    const store = yield select();
    const order = store.orderDetails.order;

    yield call(deleteJobV2, action.payload);
    yield call(fetchOrder, order._id);
  } catch (error) {
    errorToast(i18n.t("errors.unableToDeleteJob"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setOrderLoading(false));
  }
}

function* updateJobSaga(action: ReturnType<typeof sagaActions.updateJob>) {
  try {
    yield put(orderDetailsSlice.actions.setJobLoading(true));

    const store = yield select();
    const order = store.orderDetails.order;

    yield call(updateJobV2, action.payload.id, action.payload.update);
    yield call(fetchOrder, order._id);
  } catch (error) {
    errorToast(i18n.t("errors.unableToUpdateJob"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setJobLoading(false));
  }
}

function* getJobTelemetrySaga(
  action: ReturnType<typeof sagaActions.getJobTelemtry>,
) {
  try {
    yield put(orderDetailsSlice.actions.setJobTelemetryLoading(true));

    const { data } = yield call(getJobTelemetryV2, action.payload);

    yield put(orderDetailsSlice.actions.setJobTelemetry(data));
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadTelemetry"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setJobTelemetryLoading(false));
  }
}

function* updateJobTelemetrySaga(
  action: ReturnType<typeof sagaActions.updateJobTelemetry>,
) {
  try {
    yield put(orderDetailsSlice.actions.setJobTelemetryLoading(true));

    const { data } = yield call(
      updateJobTelemetryV2,
      action.payload.id,
      action.payload.update,
    );

    yield put(orderDetailsSlice.actions.setJobTelemetry(data));
  } catch (error) {
    errorToast(i18n.t("errors.unableToUpdateTelemetry"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setJobTelemetryLoading(false));
  }
}

function* updateEventSaga(action: ReturnType<typeof sagaActions.updateEvent>) {
  try {
    const store = yield select();
    const order = store.orderDetails.order;

    yield call(updateEvent, action.payload.id, action.payload.update);
    yield call(fetchOrder, order._id);
  } catch (error) {
    errorToast(i18n.t("errors.unableToReorderJobs"), undefined, error);
  }
}

function* deleteEventSaga(action: ReturnType<typeof sagaActions.deleteEvent>) {
  try {
    const store = yield select();
    const order = store.orderDetails.order;

    yield call(deleteEvent, action.payload.id);
    yield call(fetchOrder, order._id);
  } catch (error) {
    errorToast(i18n.t("errors.unableToReorderJobs"), undefined, error);
  } finally {
    yield put(orderDetailsSlice.actions.setOrderLoading(false));
  }
}

function* downloadDeliveryNoteSaga() {
  try {
    const store = yield select();
    const { data } = yield call(
      downloadDeliveryNote,
      store.orderDetails.order._id,
    );

    if (data.status === 413) {
      errorToast(i18n.t("orders.details.deliveryNote.contentError"));
    } else {
      downloadFile(
        `data:application/pdf;base64,${data}`,
        i18n.t("orders.deliveryNoteFilename", {
          order_number: store.orderDetails.order.name,
        }),
      );
    }
  } catch (error) {
    errorToast(i18n.t("errors.unableToDownloadDeliveryNote"), undefined, error);
  }
}

export default function* orderDetailsSagas() {
  yield all([
    takeEvery(sagaActions.getOrder.type, getOrderSaga),
    takeEvery(sagaActions.updateOrder.type, updateOrderSaga),
    takeEvery(sagaActions.addJob.type, addJobSaga),
    takeEvery(sagaActions.updateJob.type, updateJobSaga),
    takeEvery(sagaActions.deleteJob.type, deleteJobSaga),
    takeEvery(sagaActions.getJobTelemtry.type, getJobTelemetrySaga),
    takeEvery(sagaActions.updateJobTelemetry.type, updateJobTelemetrySaga),
    takeEvery(sagaActions.updateEvent.type, updateEventSaga),
    takeEvery(sagaActions.deleteEvent.type, deleteEventSaga),
    takeEvery(sagaActions.downloadDeliveryNote.type, downloadDeliveryNoteSaga),
  ]);
}
