import { UNIT } from "@ero/app-common/util/Units";
import { Grid2, TextField, Typography } from "@mui/material";
import { OneFieldForm } from "Components/oneFieldForm/oneFieldForm";
import { OneFieldFormSelect } from "Components/oneFieldForm/oneFieldFormSelect";
import { type AppState } from "Store";
import { updateCompanyData } from "Store/auth";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrganisationMapForm } from "../organisationMapForm";
import { useValidationSchema } from "./validationConfig";

export const CompanyInformation: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { userData, companyData, success } = useSelector(
    (state: AppState) => state.auth,
  );

  const validationRules = useValidationSchema();

  const onSubmit = useCallback((values: Record<string, string>) => {
    dispatch(updateCompanyData(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(
    () => [
      {
        value: UNIT.SQUARE_METER,
        label: t("profile.measurementUnit.squareMeter"),
      },
      {
        value: UNIT.AR,
        label: t("profile.measurementUnit.ar"),
      },
      {
        value: UNIT.HEKTAR,
        label: t("profile.measurementUnit.ha"),
      },
    ],
    [t],
  );

  const unitOfMeasurement = useMemo(
    () =>
      !companyData.unitOfMeasurement
        ? undefined
        : companyData.unitOfMeasurement,
    [companyData.unitOfMeasurement],
  );

  return (
    <Grid2 container spacing={4} maxWidth={"lg"}>
      <Grid2 size={12}>
        <Typography variant="h4">
          {t("profile.companyInformation.title")}
        </Typography>
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="name"
          value={companyData.name}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.companyName")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <TextField
          value={`#${userData._id}`}
          label={t("profile.companyInformation.eroCustomerNumber")}
          disabled
          fullWidth
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="addressAndStreetNumber"
          value={companyData.addressAndStreetNumber}
          success={success}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.street")}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="zipCode"
          value={companyData.zipCode}
          success={success}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.zipCode")}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="city"
          value={companyData.city}
          success={success}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.city")}
        />
      </Grid2>
      <Grid2 size={6}></Grid2>
      <Grid2 size={6}>
        <OneFieldFormSelect
          name="unitOfMeasurement"
          value={unitOfMeasurement?.toString()}
          success={success}
          options={options}
          onSubmit={onSubmit}
          validationRules={validationRules.unitOfMeasurement}
          label={t("profile.measurementUnit.title")}
        />
      </Grid2>
      <Grid2 height={400} size={12}>
        <OrganisationMapForm />
      </Grid2>
    </Grid2>
  );
};
