import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/company";
import {
  CompanyResponseBody,
  CompanyUpdateRequestBody,
} from "@ero/app-common/v2/routes/models/company";
import { apiInstanceV2 } from "App/axios";

export const getCompany = async (id: number | "self" = "self") =>
  await apiInstanceV2.get<CompanyResponseBody>(
    baseUrl + SubUrls.BY_ID.replace(":id", id.toString()),
  );

export const updateCompany = async (
  id: number | "self",
  update: CompanyUpdateRequestBody,
) =>
  await apiInstanceV2.patch<CompanyResponseBody>(
    baseUrl + SubUrls.BY_ID.replace(":id", id.toString()),
    update,
  );
