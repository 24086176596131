import { GridValidRowModel } from "@mui/x-data-grid";
import { OptionType } from "Types";

export const getParcelName = (
  regionette: string,
  grapeVarieties: OptionType[],
  grapeVariety?: number,
) => {
  const grapeVarietyName = grapeVarieties
    .find((option) => option.value === grapeVariety)
    ?.label.split(" (")[0];
  return `${grapeVarietyName ?? ""} ${regionette}`.trim();
};

const replaceRegionette = (
  newName: string,
  oldRegionette: string,
  newRegionette: string,
) => {
  if (newName.includes(oldRegionette)) {
    return newName.replace(oldRegionette ?? "", newRegionette ?? "");
  } else {
    return `${newName} ${newRegionette ?? ""}`.trim();
  }
};

const replaceGrapeVariety = (
  newName: any,
  newGrapeVariety: number | undefined,
  oldGrapeVariety: number | undefined,
  grapeVarieties: OptionType[],
) => {
  const newGrapeVarietyName = grapeVarieties
    .find((option) => option.value === newGrapeVariety)
    ?.label.split(" (")[0];
  const oldGrapeVarietyName = grapeVarieties
    .find((option) => option.value === oldGrapeVariety)
    ?.label.split(" (")[0];
  if (newName.includes(oldGrapeVarietyName)) {
    return newName.replace(
      oldGrapeVarietyName ?? "",
      newGrapeVarietyName ?? "",
    );
  } else {
    return `${newGrapeVarietyName ?? ""} ${newName}`.trim();
  }
};

const getNumberSuffix = (
  oldNumberSuffix: string | undefined,
  newRow: GridValidRowModel,
  oldRow: GridValidRowModel,
  parcelNameCounts: { [parcelName: string]: number },
  newName: string,
) => {
  const shouldRecomputeNumberSuffix =
    newRow.regionette !== oldRow.regionette ||
    newRow.grapeVariety !== oldRow.grapeVariety;

  if (!shouldRecomputeNumberSuffix) {
    return oldNumberSuffix ?? "";
  }

  const parcelNameCount = parcelNameCounts[newName];
  const newParcelNameNumber = parcelNameCount ? parcelNameCount + 1 : undefined;

  const newNumberSuffix = newParcelNameNumber ? `#${newParcelNameNumber}` : "";
  return newNumberSuffix;
};

export const getNewParcelName = (
  newRow: GridValidRowModel,
  oldRow: GridValidRowModel,
  grapeVarieties: OptionType[],
  parcelNameCounts: { [parcelName: string]: number },
) => {
  const hasCustomName = oldRow.name != newRow.name;
  if (hasCustomName) {
    return newRow.name;
  }
  const shouldGenerateNewName =
    oldRow.regionette !== newRow.regionette ||
    oldRow.grapeVariety !== newRow.grapeVariety;
  if (!shouldGenerateNewName) {
    return newRow.name;
  }

  const nameParts = newRow.name.split(/(Parzelle)?\s#/);
  let newName = nameParts[0];

  newName = replaceGrapeVariety(
    newName,
    newRow.grapeVariety,
    oldRow.grapeVariety,
    grapeVarieties,
  );
  newName = replaceRegionette(newName, oldRow.regionette, newRow.regionette);
  newName = newName.trim();

  const numberSuffix = getNumberSuffix(
    nameParts.slice(-1),
    newRow,
    oldRow,
    parcelNameCounts,
    newName,
  );

  newName = `${newName} ${numberSuffix}`.trim();
  return newName;
};
