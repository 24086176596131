import { PERM } from "@ero/app-common/enums";
import { CompanyResponseBody } from "@ero/app-common/v2/routes/models/company";
import {
  changePassword,
  forgotPassword,
  getProfileDetails,
  login,
  refreshToken,
  resetPassword,
  updateUserProfile,
  verifyPassword,
} from "Api/auth";
import { getCompany, updateCompany } from "Api/companies";
import i18n from "i18n/i18n";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { errorToast, successToast } from "Services";
import { persistor } from "Store/store";
import { type ApiResponse, type TokenType, type UserDataType } from "Types";
import { setSentryUser } from "Utils/sentry";
import { closeWs, openWs } from "../general";
import {
  AUTH_ACTION_TYPES,
  type IChangeLanguage,
  type IChangePassword,
  type IForgotPasswordRequestAction,
  type ILoginRequestAction,
  type IResetPasswordRequestAction,
  type IUpdateCompanyData,
  type IUpdateUserData,
  type IVerifyPasswordRequestAction,
} from "./action-types";
import {
  loginSuccess,
  refreshAccessToken,
  resetMeta,
  setCompanyDetails,
  setError,
  setLanguage,
  setLoading,
  setResetToken,
  setSuccess,
  setUserData,
} from "./actions";

export function* initialAuthSaga() {
  try {
    const store = yield select();
    const authReducer = store.auth;
    const tokens = authReducer.tokens;

    if (!tokens) {
      throw new Error("no tokens");
    }

    yield put(loginSuccess(tokens));

    const [{ data: userData }, { data: companyData }]: [
      ApiResponse<UserDataType>,
      ApiResponse<CompanyResponseBody>,
    ] = yield all([
      call(getProfileDetails, tokens.accessToken),
      call(getCompany),
    ]);

    yield put(setUserData(userData));
    yield put(setCompanyDetails(companyData));

    setSentryUser(userData);

    yield put(openWs());
  } catch (_) {
    yield call(logoutSaga);
  }
}

export function* loginSaga({ payload }: ILoginRequestAction) {
  const { credentials } = payload;

  try {
    yield put(setLoading());

    const { data: tokens }: ApiResponse<TokenType> = yield call(
      login,
      credentials,
    );

    yield put(loginSuccess(tokens));

    const [{ data: userData }, { data: companyData }]: [
      ApiResponse<UserDataType>,
      ApiResponse<CompanyResponseBody>,
    ] = yield all([
      call(getProfileDetails, tokens.accessToken),
      call(getCompany),
    ]);

    if (userData.permission !== PERM.ORGANIZER) {
      throw new Error("Invalid user permissions");
    } else {
      setSentryUser(userData);

      yield put(openWs());

      yield put(setUserData(userData));
      yield put(setCompanyDetails(companyData));

      yield put(setSuccess());
    }
  } catch (_) {
    yield put(setError());

    errorToast(i18n.t("notification.wrongCredentials"));
  }
}

export function* logoutSaga() {
  setSentryUser(null);
  yield put(closeWs());

  persistor.purge();

  window.location.replace(`/login`);
}

export function* verifyPasswordSaga({ payload }: IVerifyPasswordRequestAction) {
  const { credentials } = payload;

  try {
    yield put(setLoading());

    const { data }: ApiResponse<TokenType> = yield call(
      verifyPassword,
      credentials,
    );

    yield put(setResetToken(data.resetToken));

    yield put(setSuccess());

    // yield put(push(ROUTES.AUTH.RESET_PASSWORD));
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* resetPasswordSaga({ payload }: IResetPasswordRequestAction) {
  const { passwordData } = payload;

  try {
    yield put(setLoading());

    yield call(resetPassword, passwordData);

    yield put(setSuccess());

    successToast(i18n.t("notification.passwordResetSuccess"));

    // yield put(push(ROUTES.AUTH.LOGIN));
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* forgotPasswordSaga({ payload }: IForgotPasswordRequestAction) {
  const { email } = payload;

  try {
    yield put(setLoading());

    yield call(forgotPassword, { userName: email });

    yield put(setSuccess());

    successToast(i18n.t("notification.forgotPasswordSuccess"));

    // yield put(push(ROUTES.AUTH.LOGIN));
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* updateUserDataSaga({ payload }: IUpdateUserData) {
  const { values } = payload;

  try {
    yield put(setLoading());

    const store = yield select();

    const { data }: ApiResponse<UserDataType> = yield call(
      updateUserProfile,
      store.auth.userData._id,
      values,
    );

    // add cache buster as backend returns new avatar url with the same file identifier
    data.avatar = data.avatar?.startsWith("data:image")
      ? data.avatar
      : `${data.avatar}&t=` + new Date().getTime();

    setSentryUser(data);
    yield put(setUserData(data));

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* updateCompanyDataSaga({ payload }: IUpdateCompanyData) {
  const { values } = payload;

  try {
    yield put(setLoading());

    const { data } = yield call(updateCompany, "self", { ...values });

    // add cache buster as backend returns new avatar url with the same file identifier
    data.avatar = data.avatar?.startsWith("data:image")
      ? data.avatar
      : `${data.avatar}&t=` + new Date().getTime();

    yield put(setCompanyDetails(data));

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* changeLanguageSaga({ payload }: IChangeLanguage) {
  const { lang } = payload;

  try {
    const store = yield select();

    const { data: updatedUserData } = yield call(
      updateUserProfile,
      store.auth.userData._id,
      { language: lang },
    );

    setSentryUser(updatedUserData);

    yield put(setLanguage(lang));
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* changePasswordSaga({ payload }: IChangePassword) {
  const { data } = payload;

  try {
    yield put(setLoading());

    yield call(changePassword, data);

    yield put(setSuccess());

    successToast("notification.success.changePassword");

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* getUserDataSaga() {
  try {
    const store = yield select();
    const authReducer = store.auth;

    const { data: updatedUserData } = yield call(
      getProfileDetails,
      authReducer.tokens.accessToken,
    );

    setSentryUser(updatedUserData);

    yield put(setUserData(updatedUserData));
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* refreshTokenSaga() {
  const store = yield select();
  const authReducer = store.auth;

  const { data } = yield call(refreshToken, authReducer.tokens.refreshToken);

  yield put(refreshAccessToken(data.accessToken));
}

export default function* authSagas() {
  yield all([
    takeEvery(AUTH_ACTION_TYPES.INITIAL_REQUEST, initialAuthSaga),
    takeEvery(AUTH_ACTION_TYPES.LOGIN_REQUEST, loginSaga),
    takeEvery(AUTH_ACTION_TYPES.LOGOUT_REQUEST, logoutSaga),
    takeEvery(AUTH_ACTION_TYPES.VERIFY_PASS_REQUEST, verifyPasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.RESET_PASS_REQUEST, resetPasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.UPDATE_USERDATA, updateUserDataSaga),
    takeEvery(AUTH_ACTION_TYPES.UPDATE_COMPANY_DETAILS, updateCompanyDataSaga),
    takeEvery(AUTH_ACTION_TYPES.CHANGE_LANGUAGE, changeLanguageSaga),
    takeEvery(AUTH_ACTION_TYPES.CHANGE_PASSWORD, changePasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.GET_USERDATA, getUserDataSaga),
  ]);
}
