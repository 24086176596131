import { Box, Grid2 } from "@mui/material";
import { Loader } from "Components";
import { NoOrdersPlaceholder } from "Components/noOrdersPlaceholder/noOrderPlaceholder";
import { NoSearchResultsPlaceholder } from "Components/noSearchResultsPlaceholder";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrderDetailsModal } from "Screens/planningV2/components/orderDetailsModal/orderDetailsModal";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { OrderItem } from "./components/orderItem";

type OrderListProps = {
  showCreateOrderModal: () => void;
};

export const OrderList: React.FC<OrderListProps> = ({
  showCreateOrderModal,
}) => {
  const dispatch = useDispatch();

  const { orders, loadingOrders, hasFilters, highlightedOrder } = useSelector(
    (state: AppState) => state.planningV2,
  );

  const [orderDetailId, setOrderDetailId] = useState<number>();

  const showOrderDetails = useCallback((id: number) => {
    setOrderDetailId(id);
  }, []);

  const hideOrderDetails = useCallback(() => {
    setOrderDetailId(undefined);
  }, []);

  useEffect(() => {
    dispatch(sagaActions.fetchOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadingOrders && (
        <Box
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </Box>
      )}
      {!loadingOrders && (
        <Grid2
          container
          sx={{
            height: "100%",
            mx: 3,
            pt: 3,
            alignItems: "center",
            alignContent: "flex-start",
          }}
        >
          {orders.length > 0 &&
            orders.map((order) => (
              <OrderItem
                key={order._id}
                order={order}
                highlight={highlightedOrder === order._id}
                onDetailsBtnClick={showOrderDetails}
              />
            ))}
          {orders.length === 0 && !hasFilters && (
            <NoOrdersPlaceholder onAddClick={showCreateOrderModal} />
          )}
          {orders.length === 0 && hasFilters && (
            <NoSearchResultsPlaceholder
              title={t("components.calendar.sidebar.searchPlaceholderTitle")}
              text={t("components.calendar.sidebar.searchPlaceholderText")}
            />
          )}
          {orderDetailId && (
            <OrderDetailsModal
              orderId={orderDetailId}
              open={orderDetailId !== undefined}
              onClose={hideOrderDetails}
            />
          )}
        </Grid2>
      )}
    </>
  );
};
