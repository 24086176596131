export enum DRAWINGMODE {
  MARKER = 0,
  POLYGON = 1,
}

export const lineSymbol = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  scale: 4,
  strokeColor: "#FF0000",
};

export enum POLYGON_TRANSFORMATION_MODE {
  NONE = 0,
  MERGE = 1,
  SPLIT = 2,
}
