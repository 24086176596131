import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Divider, IconButton, Stack, TextField } from "@mui/material";
import { VALIDATION_ERRORS } from "Constants";
import { successToast } from "Services";
import { Field, Form, Formik, FormikContextType } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface OneFieldFormProps {
  name: string;
  label: string;
  value: number | string | undefined;
  validationRules?: Yup.StringSchema | Yup.NumberSchema;
  onSubmit: (values: { [name: string]: any }) => void;
  success: boolean;
  multiline?: boolean;
  size?: "small" | "medium";
  showButtonsBelow?: boolean;
}

export const OneFieldForm: React.FC<OneFieldFormProps> = ({
  name,
  label,
  value,
  validationRules,
  onSubmit,
  success,
  multiline = false,
  size = "medium",
  showButtonsBelow,
}) => {
  const formRef = useRef<FormikContextType<{ [name: string]: any }>>(null);
  const [showToastOnSuccess, setShowToastOnSuccess] = useState(false);
  const [t] = useTranslation();

  const submitHandler = useCallback(
    (values: { [name: string]: string }): void => {
      if (formRef.current?.dirty) {
        setShowToastOnSuccess(true);
        onSubmit(values);
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    if (showToastOnSuccess && success) {
      successToast(t("general.buttons.saved"));
      setShowToastOnSuccess(false);
    }
  }, [showToastOnSuccess, success, t]);

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{ [name]: value?.toString() || "" }}
        onSubmit={submitHandler}
        validationSchema={Yup.object().shape({
          [name]:
            validationRules ||
            Yup.string().required(VALIDATION_ERRORS.REQUIRED),
        })}
        enableReinitialize
      >
        <Form style={{ width: "100%" }}>
          <Field name={name}>
            {({ field, form: { isValid, dirty, resetForm }, meta }) => (
              <Stack
                direction={showButtonsBelow ? "column" : "row"}
                spacing={dirty ? 2 : 0}
                alignItems={!showButtonsBelow ? "center" : undefined}
              >
                <TextField
                  {...field}
                  label={label}
                  error={!!meta.error}
                  multiline={multiline}
                  rows={7}
                  fullWidth
                  size={size}
                />

                <Stack
                  direction="row"
                  spacing={1}
                  height={showButtonsBelow ? 40 : undefined}
                >
                  {isValid && dirty && (
                    <>
                      <IconButton
                        type="submit"
                        disabled={!isValid || !dirty}
                        color={isValid && dirty ? "success" : "default"}
                      >
                        <CheckIcon />
                      </IconButton>
                      <Divider orientation="vertical" flexItem />
                      <IconButton
                        disabled={!isValid || !dirty}
                        onClick={() => {
                          resetForm();
                        }}
                        color={isValid && dirty ? "error" : "default"}
                      >
                        <ClearIcon />
                      </IconButton>
                    </>
                  )}
                </Stack>
              </Stack>
            )}
          </Field>
        </Form>
      </Formik>
    </>
  );
};
