import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { DeleteOutline, DragHandle } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import React, { useMemo } from "react";
import { useJobIndex } from "Screens/order-details/hooks/useJobIndex";
import { getJobStatusColor } from "Utils";

type IJobOverlay = {
  job: JobResponseBody;
};

export const JobOverlay: React.FC<IJobOverlay> = ({ job }) => {
  const index = useJobIndex(job._id);
  const jobStatusColor = useMemo(
    () => getJobStatusColor(job.status).split(".")[1] ?? "default",
    [job.status],
  );

  return (
    <Paper
      elevation={2}
      square
      sx={(theme) => ({
        borderLeft: `5px solid ${theme.palette.status[jobStatusColor]}`,
      })}
    >
      <ListItem
        disablePadding
        secondaryAction={
          <>
            <IconButton disableRipple>
              <DragHandle
                sx={{
                  cursor: "grabbing",
                }}
              />
            </IconButton>
            <IconButton
              edge="end"
              disableRipple
              sx={{
                cursor: "grabbing",
              }}
            >
              <DeleteOutline />
            </IconButton>
          </>
        }
      >
        <ListItemButton sx={{ cursor: "grabbing" }}>
          <ListItemIcon sx={{ minWidth: 25 }}>{index}</ListItemIcon>
          <ListItemText
            primary={job.name}
            secondary={job.parcel.name}
            sx={{ pr: 3 }}
          />
        </ListItemButton>
      </ListItem>
    </Paper>
  );
};
