import { SqMeter } from "@ero/app-common/util/Units";
import {
  convertAreaToCustom,
  UnitSymbol,
} from "@ero/app-common/util/convertArea";
import { AccessTime, FmdGood } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  Grid2,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTotalWorkTime } from "Hooks/totalWorkTime";
import { AppState } from "Store";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart } from "recharts";
import { Deadline } from "./deadline";

export const OverviewCard: React.FC = () => {
  const [t] = useTranslation();

  const theme = useTheme();

  const { order } = useSelector((state: AppState) => state.orderDetails);
  const selectedUnit = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const totalArea = useMemo(
    () =>
      convertAreaToCustom(
        (order?.jobDetails?.parcelSize as SqMeter) ?? 0,
        selectedUnit,
      ),
    [order, selectedUnit],
  );
  const totalWorkTime = useTotalWorkTime(order?.jobDetails?.jobs || []);

  const isFullHDorLess = useMediaQuery("@media (max-width:1920px)");
  const isFullHD125orLess = useMediaQuery("@media (max-width: 1536px)"); // Full HD at 125% Zoom = 80% resolution

  const chartData = useMemo(
    () => [
      {
        name: t("general.statuses.open"),
        value: order?.jobDetails?.jobCountOpen,
        color: theme.palette.status.open,
      },
      {
        name: t("general.statuses.drivingToLocation"),
        value: order?.jobDetails?.jobCountDrivingToLocation,
        color: theme.palette.status.driving,
      },
      {
        name: t("general.statuses.inWork"),
        value: order?.jobDetails?.jobCountInWork,
        color: theme.palette.status.inwork,
      },
      {
        name: t("general.statuses.pause"),
        value: order?.jobDetails?.jobCountPause,
        color: theme.palette.status.pause,
      },
      {
        name: t("general.statuses.done"),
        value: order?.jobDetails?.jobCountDone,
        color: theme.palette.status.done,
      },
    ],
    [
      t,
      order?.jobDetails?.jobCountOpen,
      order?.jobDetails?.jobCountDrivingToLocation,
      order?.jobDetails?.jobCountInWork,
      order?.jobDetails?.jobCountPause,
      order?.jobDetails?.jobCountDone,
      theme.palette.status.open,
      theme.palette.status.driving,
      theme.palette.status.inwork,
      theme.palette.status.pause,
      theme.palette.status.done,
    ],
  );

  const pieChartSize = useMemo(() => {
    const baseSize = 10 + +!isFullHD125orLess * 5 + +!isFullHDorLess * 5;
    const size = parseInt(theme.spacing(baseSize).replace("px", ""));
    return size;
  }, [isFullHDorLess, isFullHD125orLess, theme]);

  return (
    <Card>
      <CardContent>
        <Grid2 container alignItems={"center"} justifyContent={"space-around"}>
          <Grid2 size={12}>
            <Typography variant="h6">{t("orderOverview.overview")}</Typography>
          </Grid2>
          <Grid2>
            {order?.jobDetails && (
              <PieChart
                width={pieChartSize}
                height={pieChartSize}
                style={{ margin: "0 auto" }}
              >
                <Pie
                  data={chartData}
                  innerRadius="60%"
                  outerRadius="100%"
                  dataKey="value"
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            )}
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              xl: 9,
            }}
          >
            <List>
              <ListItem>
                <ListItemIcon>
                  <AccessTime />
                </ListItemIcon>
                <ListItemText
                  primary={totalWorkTime}
                  secondary={t("orderOverview.totalTime")}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <FmdGood />
                </ListItemIcon>
                <ListItemText
                  primary={
                    totalArea
                      ? `${totalArea.toFixed(2)} ${UnitSymbol[selectedUnit]}`
                      : `- ${UnitSymbol[selectedUnit]}`
                  }
                  secondary={t("general.labels.totalArea")}
                />
              </ListItem>{" "}
              <Divider />
              <Deadline />
            </List>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};
