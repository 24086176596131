import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Fab, Stack } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import appLogo from "Assets/Logo_EROplan_negativ_RGB.png";
import { ROUTES } from "Constants";
import { AppState } from "Store";
import { logout, toggleSidebar } from "Store/auth";
import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InfoMenu from "./infomenu";
import MainMenu from "./mainmenu";
import { ProfileMenu } from "./profilemenu";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "visible",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "visible",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(1),
  padding: theme.spacing(0, 1),
  minHeight: "fit-content !important",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const iconMatrix = {
  sidebarOpen: {
    rtl: <ChevronRightIcon fontSize="small" />,
    ltr: <ChevronLeftIcon fontSize="small" />,
  },
  sidebarClosed: {
    rtl: <ChevronLeftIcon fontSize="small" />,
    ltr: <ChevronRightIcon fontSize="small" />,
  },
};

const OpenCloseButton: React.FC<{ sidebarOpen: boolean }> = ({
  sidebarOpen,
}) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const handleDrawerOpen = useCallback(() => {
    dispatch(toggleSidebar(true));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerClose = useCallback(() => {
    dispatch(toggleSidebar(false));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const icon = useMemo(() => {
    return iconMatrix[sidebarOpen ? "sidebarOpen" : "sidebarClosed"][
      theme.direction
    ];
  }, [sidebarOpen, theme.direction]);

  const button = useMemo(() => {
    let onClick = () => {};
    if (sidebarOpen) {
      onClick = handleDrawerClose;
    } else {
      onClick = handleDrawerOpen;
    }
    return (
      <Fab
        size="small"
        sx={{
          position: "absolute",
          right: -16,
          top: sidebarOpen ? 184 : 124,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: "top 0.5s",
        }}
        onClick={onClick}
      >
        {icon}
      </Fab>
    );
  }, [sidebarOpen, handleDrawerClose, handleDrawerOpen, icon]);

  return button;
};

const EroDrawer: React.FC<PropsWithChildren> = ({ children }) => {
  const { sidebarOpen, /*colorMode,*/ companyData } = useSelector(
    (state: AppState) => state.auth,
  );

  const dispatch = useDispatch();

  // Re-add when fixing APP-2881
  // const changeColorModeHandler = useCallback((mode: ColorMode) => {
  //   dispatch(changeColorMode(mode));
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onLogout = useCallback(() => {
    dispatch(logout());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline enableColorScheme />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ backgroundColor: "#3d3d3d" }}>
          <Link to={ROUTES.MAIN.DASHBOARD}>
            <div
              style={{
                width: 250,
                verticalAlign: "top",
                display: "inline-block",
              }}
            >
              <img
                src={appLogo}
                alt="EROplan Logo"
                style={{ verticalAlign: "top", width: 150 }}
              />
            </div>
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* in here we could put our title */}
          </Typography>
          <InfoMenu />
          <ProfileMenu onLogoutClick={onLogout} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={sidebarOpen}
        sx={{ overflow: "visible" }}
      >
        <Toolbar />
        <DrawerHeader sx={{}}>
          <Stack direction="column" alignItems={"center"} maxWidth={"100%"}>
            <Avatar
              alt={companyData.name as string}
              src={companyData.avatar ?? "n/a"}
              sx={{
                width: sidebarOpen ? 100 : 50,
                height: sidebarOpen ? 100 : 50,

                transition: "width 0.5s, height 0.5s",
              }}
            ></Avatar>
            {sidebarOpen && (
              <Typography noWrap sx={{ textAlign: "center", maxWidth: "100%" }}>
                {companyData.name}
              </Typography>
            )}
          </Stack>
          <OpenCloseButton sidebarOpen={sidebarOpen} />
        </DrawerHeader>
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
          <MainMenu />
        </Box>

        {/*
        Re-add when fixing APP-2881
        <ChangeColorModeSwitch
          onChange={changeColorModeHandler}
          currentColorMode={colorMode}
        /> */}
      </Drawer>
      <Box
        component="main"
        sx={{
          flex: 1,
          flexGrow: 1,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Toolbar />
        <Box sx={{ height: "calc(100vh - 64px)", overflow: "auto" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default EroDrawer;
