import { useSortable } from "@dnd-kit/sortable";
import { UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { DragHandle } from "@mui/icons-material";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Switch,
} from "@mui/material";
import { useUserTitle } from "Hooks/userTitle";
import React, { useMemo } from "react";

type UserListItemProps = {
  user: UserResponseBody;
  handleToggle: () => void;
  selected: boolean;
  disabled: boolean;
};

export const UserListItem: React.FC<UserListItemProps> = ({
  user,
  handleToggle,
  selected,
  disabled,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: user._id,
    });

  const style = useMemo(
    () =>
      transform
        ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            transition,
          }
        : undefined,
    [transform, transition],
  );

  const userTitle = useUserTitle(user);

  return (
    <Paper
      ref={setNodeRef}
      elevation={3}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        margin: 2,
        touchAction: "none",
      })}
      style={style}
    >
      <ListItem>
        <DragHandle
          {...attributes}
          {...listeners}
          sx={(theme) => ({
            cursor: "grab",
            "&:active": {
              cursor: "grabbing",
            },
            mr: 2,
            // avoid border if icon has focus
            outline: "none",
            color: theme.palette.grey[500],
          })}
        />
        <ListItemAvatar>
          <Avatar sx={{ width: 24, height: 24 }} src={user.avatar}>
            {`${user.userName[0].toUpperCase()}`}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={userTitle} />
        <Switch
          edge="end"
          onChange={handleToggle}
          checked={selected}
          disabled={disabled}
        />
      </ListItem>
    </Paper>
  );
};
