import { MAX_LENGTH, VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  companyName: Yup.string()
    .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
    .required(VALIDATION_ERRORS.REQUIRED),
  internalId: Yup.string().max(
    MAX_LENGTH.DEFAULT,
    VALIDATION_ERRORS.MAX_LENGTH,
  ),
  firstName: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  lastName: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  street: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  zipCode: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  city: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  email: Yup.string()
    .matches(VALIDATION_REGEX.email, VALIDATION_ERRORS.VALID_EMAIL)
    .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  phone: Yup.string().matches(VALIDATION_REGEX.phone, VALIDATION_ERRORS.PHONE),
  mobile: Yup.string().matches(VALIDATION_REGEX.phone, VALIDATION_ERRORS.PHONE),
  fax: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  website: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  mainContact: Yup.string().max(
    MAX_LENGTH.DEFAULT,
    VALIDATION_ERRORS.MAX_LENGTH,
  ),
});
