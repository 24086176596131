import { Info } from "@mui/icons-material";
import { Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParcelEditingContext } from "../../contexts/parcelEditingContext";
import { POLYGON_TRANSFORMATION_MODE } from "../../utils";

export const TransformationInfo: React.FC = () => {
  const { transformationMode } = useParcelEditingContext();

  const [infoAlert, setInfoAlert] = useState<{
    isDisplayed: boolean;
    text: string | undefined;
  }>({ isDisplayed: false, text: undefined });
  const [t] = useTranslation();

  useEffect(() => {
    switch (transformationMode) {
      case POLYGON_TRANSFORMATION_MODE.NONE:
        setInfoAlert({
          text: undefined,
          isDisplayed: false,
        });
        break;
      case POLYGON_TRANSFORMATION_MODE.MERGE:
        setInfoAlert({
          text: t("parcels.upload.infoLabels.selectParcels"),
          isDisplayed: true,
        });
        break;
      case POLYGON_TRANSFORMATION_MODE.SPLIT:
        setInfoAlert({
          text: t("parcels.upload.infoLabels.moveMarkers"),
          isDisplayed: true,
        });
    }
  }, [transformationMode, t]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        pointerEvents: "none",
        position: "absolute",
        bottom: 10,
        right: 65,
      }}
    >
      {infoAlert.isDisplayed && (
        <Chip
          size="small"
          color="info"
          icon={<Info />}
          label={infoAlert.text}
        />
      )}
    </Stack>
  );
};
