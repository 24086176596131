import { useSortable } from "@dnd-kit/sortable";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { ListItem } from "@mui/material";
import { default as React, useMemo } from "react";
import { JobItem } from "./jobItem";

interface IJobItem {
  contextId: string;
  job?: JobResponseBody;
  isDraggable?: boolean;
  eventId?: number;
  onClick?: (event, id: number) => void;
  onDelete?: (jobId: number) => void;
}

export const SortableItem: React.FC<IJobItem> = ({
  contextId,
  job,
  isDraggable = false,
  eventId,
  onClick,
  onDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: job?._id ?? contextId + "_placeHolderItem",
      data: { ...job, eventId },
    });

  const style = useMemo(
    () =>
      transform
        ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            transition,
          }
        : undefined,
    [transform, transition],
  );

  if (!job)
    return (
      <ListItem ref={setNodeRef} {...attributes} {...listeners}></ListItem>
    );

  return (
    <JobItem
      style={style}
      ref={setNodeRef}
      job={job}
      onClick={onClick}
      onDelete={onDelete}
      isDraggable={isDraggable}
      {...attributes}
      {...listeners}
    />
  );
};
