import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Input, Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { type AppState } from "Store";
import { sagaActions } from "Store/orderDetails/orderDetailsSagaActions";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

interface IEditNoteModal {
  open: boolean;
  onClose: () => void;
}

export const EditNoteModal: React.FC<IEditNoteModal> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { order, orderLoading } = useSelector(
    (state: AppState) => state.orderDetails,
  );

  const initialValues = useMemo(
    () => ({ value: order?.notes }),
    [order?.notes],
  );

  const handleFormSubmit = useCallback(({ value }) => {
    dispatch(sagaActions.updateOrder({ notes: value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogTitle>{t("general.fields.notes")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ dirty, isValid, submitForm }) => (
          <>
            <DialogContent dividers>
              <Form>
                <Input type="text" name="value" multiline />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!isValid || !dirty || orderLoading}
                onClick={submitForm}
                startIcon={!orderLoading && <Save />}
              >
                {orderLoading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
