import { MAX_LENGTH, VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import { useMemo } from "react";
import * as Yup from "yup";

export const useValidationSchema = () => {
  return useMemo(
    () => ({
      freeField: Yup.string()
        .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
        .required(VALIDATION_ERRORS.REQUIRED),
      phone: Yup.string()
        .matches(VALIDATION_REGEX.phone, VALIDATION_ERRORS.PHONE)
        .required(VALIDATION_ERRORS.REQUIRED),
      unitOfMeasurement: Yup.string()
        .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
        .required(VALIDATION_ERRORS.REQUIRED),
    }),
    [],
  );
};
