import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { OrderDateType } from "@ero/app-common/models";
import { UpdateOrderRequestBody } from "@ero/app-common/v2/routes/models/order";
import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";
import { Form, Formik, FormikContextType, FormikProps } from "formik";
import React, { ReactNode, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { sagaActions } from "Store/orderDetails/orderDetailsSagaActions";
import { DateInputContent } from "./components/dateInputContent";
import { DayTimeContent } from "./components/dayTimeContent";
import { useValidationSchema, ValuesType } from "./formConfig";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  givenDate?: OrderDateType;
}

export const DateModal: React.FC<IProps> = ({ isOpen, onClose, givenDate }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const formikRef = useRef<FormikContextType<ValuesType>>(null);

  const validationSchema = useValidationSchema();

  const combinedInitValues: ValuesType = useMemo(
    () => ({
      dateRestrictions: {
        timeMode: givenDate?.timeMode || DateTimeMode.WITHOUTTIME,
        dateMode: givenDate?.dateMode || DateMode.FIXEDDATE,
        date: givenDate?.date || 0,
        startDate: givenDate?.startDate || 0,
        endDate: givenDate?.endDate || 0,
        dayTime: {
          startTime: givenDate?.dayTime?.startTime || undefined,
          endTime: givenDate?.dayTime?.endTime || undefined,
        },
      },
    }),
    [givenDate],
  );

  const dateOptions = [
    { value: DateMode.FIXEDDATE, label: t("orders.createModal.fixedDate") },
    { value: DateMode.DATERANGE, label: t("orders.createModal.dateRange") },
  ];

  const timeOptions = [
    {
      value: DateTimeMode.WITHOUTTIME,
      label: t("orders.createModal.withoutTime"),
    },
    { value: DateTimeMode.WITHTIME, label: t("orders.createModal.withTime") },
    {
      value: DateTimeMode.WITHDAYTIME,
      label: t("orders.createModal.withDayTime"),
    },
  ];

  const handleSubmit = (values: ValuesType) => {
    const { dateRestrictions } = values;
    let dateMode = dateRestrictions.dateMode;
    let timeMode = dateRestrictions.timeMode;

    if (
      dateRestrictions.dateMode === DateMode.FIXEDDATE &&
      !dateRestrictions.date
    ) {
      dateMode = undefined;
      timeMode = undefined;
    }
    const preparedValues: UpdateOrderRequestBody = {
      dateRestrictions: {
        ...dateRestrictions,
        dateMode,
        timeMode,
        dayTime: dateRestrictions.dayTime ?? {},
      },
    };

    dispatch(sagaActions.updateOrder(preparedValues));

    onClose();
  };

  const resetTimes = useCallback(() => {
    const formRef = formikRef.current;

    if (formRef) {
      formRef.setFieldValue("dateRestrictions.dayTime.startTime", undefined);
      formRef.setFieldValue("dateRestrictions.dayTime.endTime", undefined);
    }
  }, []);

  const resetDates = useCallback(() => {
    const formRef = formikRef.current;

    if (formRef) {
      formRef.setFieldValue("dateRestrictions.date", undefined);
      formRef.setFieldValue("dateRestrictions.startDate", undefined);
      formRef.setFieldValue("dateRestrictions.endDate", undefined);
      formRef.setFieldValue("dateRestrictions.timeMode", undefined);
      resetTimes();
    }
  }, [resetTimes]);

  const onDateModeChange = useCallback(
    (val) => val && resetDates(),
    [resetDates],
  );

  const onTimeModeChange = useCallback(
    (val) => val && resetTimes(),
    [resetTimes],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle>{t("orders.createModal.timeRange")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        validateOnMount={true}
        initialValues={combinedInitValues}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props: FormikProps<ValuesType>): ReactNode => (
          <>
            <DialogContent dividers>
              <Form>
                <Grid2 container spacing={4}>
                  <Grid2 size={6}>
                    <StaticSelectWithAutoComplete
                      name="dateRestrictions.dateMode"
                      label={t("orders.createModal.dateMode")}
                      required
                      options={dateOptions}
                      onChange={onDateModeChange}
                      nullable
                    ></StaticSelectWithAutoComplete>
                  </Grid2>
                  <Grid2 size={6}>
                    <StaticSelectWithAutoComplete
                      name="dateRestrictions.timeMode"
                      label={t("orders.createModal.timeMode")}
                      required
                      options={timeOptions}
                      onChange={onTimeModeChange}
                      nullable
                    ></StaticSelectWithAutoComplete>
                  </Grid2>
                  <Grid2 size={12}>
                    <DateInputContent />
                  </Grid2>
                  {props.values.dateRestrictions.timeMode !==
                    DateTimeMode.WITHDAYTIME && <br />}
                  <Grid2 size={12}>
                    {props.values.dateRestrictions.timeMode ==
                      DateTimeMode.WITHDAYTIME && <DayTimeContent />}
                  </Grid2>
                  {props.values.dateRestrictions.timeMode ==
                    DateTimeMode.WITHDAYTIME && <br />}
                  {props.values.dateRestrictions.timeMode ==
                    DateTimeMode.WITHDAYTIME && <br />}
                  {props.values.dateRestrictions.timeMode ==
                    DateTimeMode.WITHDAYTIME && <br />}
                </Grid2>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.submitForm}
                disabled={!props.isValid || !props.dirty}
                startIcon={<Save />}
              >
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
