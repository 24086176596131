import { HTTP_METHOD } from "@ero/app-common/enums/HTTP_METHOD";
import { LANGUAGE } from "@ero/app-common/enums/language";
import { ApiTypes } from "@ero/app-common/v2/routes";
import {
  baseUrl as companyBaseUrl,
  SubUrls as CompanySubUrls,
} from "@ero/app-common/v2/routes/company";
import { CompanyResponseBody } from "@ero/app-common/v2/routes/models/company";
import { type Action } from "redux";
import {
  type CHANGE_PASSWORD_DTO,
  type LOGIN_DTO,
  type ResetPassType,
  type TokenType,
  type UserDataType,
  type VerifyPassType,
} from "Types";

export enum AUTH_ACTION_TYPES {
  INITIAL_REQUEST = "auth/initial-request",
  LOGIN_REQUEST = "auth/login-request",
  LOGIN_SUCCESS = "auth/login-success",
  LOGOUT_REQUEST = "auth/logout-request",
  LOGOUT_SUCCESS = "auth/logout-success",
  SET_LOADING = "auth/set-loading",
  SET_SUCCESS = "auth/set-success",
  SET_ERROR = "auth/set-error",
  SET_RESET_TOKEN = "auth/set-reset-token",
  RESET_META = "auth/reset-meta",
  VERIFY_PASS_REQUEST = "auth/verify-pass-request",
  RESET_PASS_REQUEST = "auth/reset-password-request",
  REFRESH_TOKEN_SUCCESS = "auth/refresh-token",
  FORGOT_PASSWORD_REQUEST = "auth/forgot-password-request",
  SET_COMPANY_DETAILS = "auth/set-company-details",
  UPDATE_COMPANY_DETAILS = "auth/update-company-details",
  UPDATE_USERDATA = "auth/update-userdata",
  SET_USERDATA = "auth/set-userdata",
  GET_USERDATA = "auth/get-userdata",
  CHANGE_LANGUAGE = "auth/change-language",
  SET_LANGUAGE = "auth/set-language",
  CHANGE_COLORMODE = "auth/change-colormode",
  CHANGE_PASSWORD = "auth/change-password",
  TOGGLE_SIDEBAR = "auth/toggle-sidebar",
  TOGGLE_SIDEBAR_SUBMENU = "auth/toggle-sidebar-submenu",
}

export interface ISetSuccessAction extends Action {
  type: AUTH_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetLoadingAction extends Action {
  type: AUTH_ACTION_TYPES.SET_LOADING;
}

export interface ISetErrorAction extends Action {
  type: AUTH_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: AUTH_ACTION_TYPES.RESET_META;
}

export interface IInitialRequest extends Action {
  type: AUTH_ACTION_TYPES.INITIAL_REQUEST;
}

export interface ILoginRequestAction extends Action {
  type: AUTH_ACTION_TYPES.LOGIN_REQUEST;
  payload: { credentials: LOGIN_DTO };
}

export interface ILoginSuccessAction extends Action {
  type: AUTH_ACTION_TYPES.LOGIN_SUCCESS;
  payload: {
    tokens: TokenType;
  };
}

export interface ILogoutRequestAction extends Action {
  type: AUTH_ACTION_TYPES.LOGOUT_REQUEST;
  payload: { token: string };
}

export interface ILogoutSuccessAction extends Action {
  type: AUTH_ACTION_TYPES.LOGOUT_SUCCESS;
}

export interface IVerifyPasswordRequestAction extends Action {
  type: AUTH_ACTION_TYPES.VERIFY_PASS_REQUEST;
  payload: { credentials: VerifyPassType };
}

export interface IResetPasswordRequestAction extends Action {
  type: AUTH_ACTION_TYPES.RESET_PASS_REQUEST;
  payload: { passwordData: ResetPassType };
}

export interface ISetResetToken extends Action {
  type: AUTH_ACTION_TYPES.SET_RESET_TOKEN;
  payload: {
    resetToken: string;
  };
}

export interface IForgotPasswordRequestAction extends Action {
  type: AUTH_ACTION_TYPES.FORGOT_PASSWORD_REQUEST;
  payload: { email: string };
}

export interface IRefreshTokenSuccessAction extends Action {
  type: AUTH_ACTION_TYPES.REFRESH_TOKEN_SUCCESS;
  payload: {
    accessToken: string;
  };
}

export interface ISetCompanyDetails extends Action {
  type: AUTH_ACTION_TYPES.SET_COMPANY_DETAILS;
  payload: { companyData: CompanyResponseBody };
}

export interface IUpdateUserData extends Action {
  type: AUTH_ACTION_TYPES.UPDATE_USERDATA;
  payload: { values: Record<string, string> };
}

export interface ISetUserData extends Action {
  type: AUTH_ACTION_TYPES.SET_USERDATA;
  payload: { userData: UserDataType };
}

export interface IGetUserData extends Action {
  type: AUTH_ACTION_TYPES.GET_USERDATA;
}

export interface IUpdateCompanyData extends Action {
  type: AUTH_ACTION_TYPES.UPDATE_COMPANY_DETAILS;
  payload: {
    values: ApiTypes[typeof companyBaseUrl][CompanySubUrls.BY_ID][HTTP_METHOD.PATCH]["request"];
  };
}

export interface IChangeLanguage extends Action {
  type: AUTH_ACTION_TYPES.CHANGE_LANGUAGE;
  payload: { lang: LANGUAGE };
}

export interface ISetLanguage extends Action {
  type: AUTH_ACTION_TYPES.SET_LANGUAGE;
  payload: { lang: string };
}

export interface IChangeColorMode extends Action {
  type: AUTH_ACTION_TYPES.CHANGE_COLORMODE;
  payload: { mode: string };
}

export interface IChangePassword extends Action {
  type: AUTH_ACTION_TYPES.CHANGE_PASSWORD;
  payload: { data: CHANGE_PASSWORD_DTO };
}

export interface IToggleSidebar extends Action {
  type: AUTH_ACTION_TYPES.TOGGLE_SIDEBAR;
  payload: { value: boolean };
}
export interface IToggleSidebarSubmenu extends Action {
  type: AUTH_ACTION_TYPES.TOGGLE_SIDEBAR_SUBMENU;
  payload: { value: boolean };
}

export type AuthAction =
  | IInitialRequest
  | ILoginSuccessAction
  | ILogoutRequestAction
  | ILogoutSuccessAction
  | ISetSuccessAction
  | ISetLoadingAction
  | ISetErrorAction
  | IResetMetaAction
  | IVerifyPasswordRequestAction
  | IResetPasswordRequestAction
  | IForgotPasswordRequestAction
  | IRefreshTokenSuccessAction
  | ISetResetToken
  | ISetCompanyDetails
  | IUpdateUserData
  | ISetUserData
  | IGetUserData
  | IUpdateCompanyData
  | IChangeLanguage
  | ISetLanguage
  | IChangeColorMode
  | IChangePassword
  | IToggleSidebar
  | IToggleSidebarSubmenu;
