import { useDndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { JOBSTATUS } from "@ero/app-common/enums";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Lock } from "@mui/icons-material";
import { Box, ListSubheader, Stack, Tooltip, Typography } from "@mui/material";
import { useUserTitle } from "Hooks/userTitle";
import React, { useMemo } from "react";
import { OrderDetailsStateEvents } from "Store/orderDetails/orderDetailsSlice";
import { getOrderStatusColor, getPartialOrderStatus } from "Utils";
import { SORTABLE_CONTEXT } from "./jobsList";
import { SortableItem } from "./sortableItem";

const dateTimeFormat = new Intl.DateTimeFormat([], {
  dateStyle: "medium",
  timeStyle: "short",
});

interface IPartialOrder {
  title: string;
  jobs: JobResponseBody[];
  contextId: string;
  event?: OrderDetailsStateEvents[0];
  allowDragging?: boolean;
  onJobClick: (event, id: number) => void;
  onJobDelete: (id: number) => void;
}

export const PartialOrder: React.FC<IPartialOrder> = ({
  title,
  jobs,
  event,
  allowDragging = true,
  onJobClick,
  onJobDelete,
  contextId,
}) => {
  const status = getPartialOrderStatus(jobs);
  const color = getOrderStatusColor(status).split(".")[1] ?? "default";

  const { active, over } = useDndContext();

  const userTitle = useUserTitle(jobs[0].employees[0]);

  const isDraggable = useMemo(
    () =>
      allowDragging
        ? jobs.every((job) => job.status === JOBSTATUS.OPEN)
        : false,
    [jobs, allowDragging],
  );

  const jobIds = useMemo(() => jobs.map((u) => u._id), [jobs]);

  const dateRange = useMemo(
    () =>
      event &&
      `${dateTimeFormat.formatRange(
        new Date(event?.start),
        new Date(event?.end),
      )}`,
    [event],
  );

  const isUnplannedJobsSection = useMemo(
    () => contextId === SORTABLE_CONTEXT.UNPLANNED,
    [contextId],
  );

  const isCurrentlyDraggedJobUnplanned = useMemo(
    () =>
      active?.data.current?.sortable.containerId === SORTABLE_CONTEXT.UNPLANNED,
    [active?.data],
  );

  const showDropZone = useMemo(
    () => isUnplannedJobsSection && over && !isCurrentlyDraggedJobUnplanned,
    [isCurrentlyDraggedJobUnplanned, isUnplannedJobsSection, over],
  );

  const subheaderTooltip = useMemo(() => {
    if (!event) {
      return "";
    }
    return `${dateRange} - ${userTitle}`;
  }, [dateRange, event, userTitle]);

  return (
    <Box marginBottom={1}>
      <ListSubheader
        color="inherit"
        sx={(theme) => ({
          textAlign: "center",
          marginBottom: 0.1,
          borderLeft: `5px solid ${theme.palette.status[color]}`,
          backgroundColor: theme.palette.grey[300],
          lineHeight: "unset",
        })}
      >
        <Tooltip title={subheaderTooltip}>
          <Typography
            variant="button"
            sx={(theme) => ({
              color: theme.palette.getContrastText(theme.palette.grey[300]),
            })}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {event?.locked && (
                <Box
                  sx={{
                    pr: 1,
                    pt: 0.5,
                  }}
                >
                  <Lock fontSize={"inherit"} />
                </Box>
              )}
              {title}
            </Stack>
          </Typography>
        </Tooltip>
      </ListSubheader>
      <SortableContext id={contextId} items={jobIds ?? []}>
        {!showDropZone &&
          jobs.length > 0 &&
          jobs.map((job) => (
            <SortableItem
              key={job._id}
              contextId={contextId}
              job={job}
              eventId={event?._id}
              isDraggable={isDraggable}
              onClick={onJobClick}
              onDelete={onJobDelete}
            />
          ))}
      </SortableContext>
    </Box>
  );
};
