import { Alert, Box } from "@mui/material";
import { FormikValues } from "Components/parcelModal/validationConfig";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParcelEditingContext } from "../../contexts/parcelEditingContext";
import { DRAWINGMODE, POLYGON_TRANSFORMATION_MODE } from "../../utils";

export const DrawingInfo: React.FC = () => {
  const [t] = useTranslation();
  const { values } = useFormikContext<FormikValues>();

  const polyCoordsCount = useMemo(
    () => values.shape?.length,
    [values.shape?.length],
  );

  const { drawingMode, transformationMode } = useParcelEditingContext();

  const markerInfo = useMemo(
    () =>
      drawingMode === DRAWINGMODE.MARKER &&
      t("parcels.notification.markerDrawing.info"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawingMode],
  );

  const polygonInfo = useMemo(() => {
    if (drawingMode !== DRAWINGMODE.POLYGON) return "";
    else {
      if (!polyCoordsCount || polyCoordsCount < 4)
        return t("parcels.notification.polygonDrawing.infoEmptyPolygon");
      if (values.isClosedPolygon)
        return t("parcels.notification.polygonDrawing.infoClosedPolygon");
      else return t("parcels.notification.polygonDrawing.infoPolyDrawing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingMode, values.isClosedPolygon, polyCoordsCount]);

  if (transformationMode !== POLYGON_TRANSFORMATION_MODE.NONE) {
    return <></>;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 130,
        opacity: 1,
        marginTop: 1,
        marginRight: 1,
      }}
    >
      <Alert severity="info">
        {markerInfo}
        {polygonInfo}
      </Alert>
    </Box>
  );
};
