import PolylineIcon from "@mui/icons-material/Polyline";
import RoomIcon from "@mui/icons-material/Room";
import { Box, ToggleButtonGroup, Tooltip } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParcelEditingContext } from "../../contexts/parcelEditingContext";
import { DRAWINGMODE, POLYGON_TRANSFORMATION_MODE } from "../../utils";

export const DrawingControls: React.FC = () => {
  const [t] = useTranslation();

  const { drawingMode, setDrawingMode, transformationMode } =
    useParcelEditingContext();

  if (transformationMode !== POLYGON_TRANSFORMATION_MODE.NONE) {
    return <></>;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 1,
        marginLeft: 1,
        marginTop: 1,
      }}
    >
      <ToggleButtonGroup
        value={drawingMode}
        sx={{
          backgroundColor: "#fff",
        }}
        size="large"
        color="primary"
        exclusive
        onChange={(e, value) => {
          if (value !== null) setDrawingMode(value);
        }}
        aria-label="drawing-mode"
      >
        <Tooltip title={t("parcels.notification.markerDrawing.tooltip")}>
          <ToggleButton value={DRAWINGMODE.MARKER} aria-label="marker">
            <RoomIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title={t("parcels.notification.polygonDrawing.tooltip")}>
          <ToggleButton value={DRAWINGMODE.POLYGON} aria-label="polygon">
            <PolylineIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Box>
  );
};
