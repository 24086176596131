import { LANGUAGE } from "@ero/app-common/enums/language";
import { UNIT } from "@ero/app-common/util/Units";
import { CompanyResponseBody } from "@ero/app-common/v2/routes/models/company";
import { type TokenType, type UserDataType } from "Types";
import { AUTH_ACTION_TYPES, type AuthAction } from "./action-types";

const DEFAULT_COLOR_MODE = "light";

export type AuthStateType = {
  userData: UserDataType;
  companyData: CompanyResponseBody;
  selectedLang: string;
  colorMode: string;
  tokens: TokenType;
  success: boolean;
  loading: boolean;
  error: boolean;
  sidebarOpen: boolean;
  sidebarSubMenuOpen: boolean;
  initialLoading: boolean;
};

const initialState: AuthStateType = {
  userData: {} as UserDataType,
  companyData: {
    _id: -1,
    email: "",
    unitOfMeasurement: UNIT.HEKTAR,
  },
  selectedLang: LANGUAGE.DE,
  colorMode: DEFAULT_COLOR_MODE,
  tokens: {} as TokenType,
  success: false,
  loading: false,
  error: false,
  sidebarOpen: true,
  sidebarSubMenuOpen: false,
  initialLoading: true,
};

export const authReducer = (
  state = initialState,
  action: AuthAction,
): AuthStateType => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        tokens: action.payload.tokens,
        initialLoading: false,
      };

    case AUTH_ACTION_TYPES.SET_COMPANY_DETAILS:
      return {
        ...state,
        companyData: action.payload.companyData,
      };

    case AUTH_ACTION_TYPES.SET_USERDATA:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload.userData },
        selectedLang: action.payload.userData.language ?? state.selectedLang,
      };

    case AUTH_ACTION_TYPES.SET_RESET_TOKEN: {
      return {
        ...state,
        tokens: {
          ...state.tokens,
          resetToken: action.payload.resetToken,
        },
      };
    }

    case AUTH_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };

    case AUTH_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };

    case AUTH_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
      };

    case AUTH_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
      };

    case AUTH_ACTION_TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          accessToken: action.payload.accessToken,
        },
      };

    case AUTH_ACTION_TYPES.SET_LANGUAGE:
      return {
        ...state,
        userData: {
          ...state.userData,
          language: action.payload.lang,
        },
        selectedLang: action.payload.lang,
      };

    case AUTH_ACTION_TYPES.CHANGE_COLORMODE:
      return {
        ...state,
        colorMode: action.payload.mode,
      };

    case AUTH_ACTION_TYPES.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: action.payload.value,
      };

    case AUTH_ACTION_TYPES.TOGGLE_SIDEBAR_SUBMENU:
      return {
        ...state,
        sidebarSubMenuOpen: action.payload.value,
      };

    default:
      return state;
  }
};
