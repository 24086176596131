import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { FormikValues } from "Components/parcelModal/validationConfig";
import { useMerge } from "Hooks/useMergePolygon";
import { useSplit } from "Hooks/useSplitPolygon";
import { NotificationService } from "Services";
import { PolygonWithSize } from "Utils/turf/types";
import { FormikErrors } from "formik";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { POLYGON_TRANSFORMATION_MODE } from "../utils";

export const useTransformation = (
  setTransformationMode: Dispatch<SetStateAction<POLYGON_TRANSFORMATION_MODE>>,
  parcelIdToMerge: number | undefined,
  setParcelIdToMerge: Dispatch<SetStateAction<number | undefined>>,
  splitLineRef: React.MutableRefObject<google.maps.Polyline | null>,
  onParcelSplit: (splittedParcel: PolygonWithSize) => void,
  onParcelMerge: (mergedParcelId: number) => void,
  allParcelsOfCustomer: ParcelsResponseBody["data"] | undefined,
  values: FormikValues,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<FormikValues>>,
) => {
  const [t] = useTranslation();

  const split = useSplit();
  const merge = useMerge();

  const splitParcel = useCallback(() => {
    try {
      const lineCoords = splitLineRef.current?.getPath().getArray();

      if (lineCoords?.length) {
        if (!values.shape) {
          throw Error("Unable to split parcels", {
            cause: t(
              "notification.error.polygonSplitting.cause.operationFailed",
            ),
          });
        }

        // Add first point of poly at the end again to make it usable with turf
        const firstVertexEqualsLast = values.shape[0] == values.shape[-1];

        const splitParcelPolygonsWithSize: PolygonWithSize[] = split(
          firstVertexEqualsLast
            ? values.shape
            : [...values.shape, values.shape[0]],
          lineCoords,
        );

        setFieldValue("shape", splitParcelPolygonsWithSize[0].shape);
        setFieldValue("size", splitParcelPolygonsWithSize[0].size);
        setFieldValue("sizeField", SIZE_FIELD.SIZE_AUTOMATIC);

        onParcelSplit(splitParcelPolygonsWithSize[1]);
      }
    } catch (err) {
      let message = "operationFailed";
      if (err instanceof Error && err.cause) {
        message = err.cause as string;
      }
      NotificationService.error(
        t("notification.error.polygonSplitting.title"),
        message,
      );
    } finally {
      setTransformationMode(POLYGON_TRANSFORMATION_MODE.NONE);
    }
  }, [
    onParcelSplit,
    setFieldValue,
    setTransformationMode,
    split,
    splitLineRef,
    t,
    values,
  ]);

  const mergeParcels = useCallback(() => {
    try {
      if (!parcelIdToMerge) return;
      const parcelToMerge = allParcelsOfCustomer?.find(
        (p) => p._id === parcelIdToMerge,
      );

      if (!values.shape || !parcelToMerge?.shape)
        throw new Error(undefined, {
          cause: t("notification.error.polygonMerging.cause.noTwoSelected"),
        });

      // Add first point of poly at the end again to make it usable with turf
      const firstShapeVertexEqualsLast = values.shape[0] == values.shape[-1];
      const firstMergeParcelVertexEqualsLast =
        parcelToMerge.shape[0] == parcelToMerge.shape[-1];

      const mergedPolygonWithSize = merge([
        firstShapeVertexEqualsLast
          ? values.shape
          : [...values.shape, values.shape[0]],
        firstMergeParcelVertexEqualsLast
          ? parcelToMerge.shape
          : [...parcelToMerge.shape, parcelToMerge.shape[0]],
      ]);

      setFieldValue("shape", mergedPolygonWithSize.shape);
      setFieldValue("size", mergedPolygonWithSize.size);
      setFieldValue("sizeField", SIZE_FIELD.SIZE_AUTOMATIC);

      onParcelMerge(parcelIdToMerge);
    } catch (err) {
      let message = "operationFailed";
      if (err instanceof Error && err.cause) {
        message = err.cause as string;
      }
      NotificationService.error(
        t("notification.error.polygonMerging.title"),
        message,
      );
    } finally {
      setParcelIdToMerge(undefined);
      setTransformationMode(POLYGON_TRANSFORMATION_MODE.NONE);
    }
  }, [
    allParcelsOfCustomer,
    merge,
    onParcelMerge,
    parcelIdToMerge,
    setFieldValue,
    setParcelIdToMerge,
    setTransformationMode,
    t,
    values.shape,
  ]);

  return {
    splitParcel,
    mergeParcels,
  };
};
