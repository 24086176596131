import {
  IMPORT_SOURCE_BAY,
  IMPORT_SOURCE_BW,
  IMPORT_SOURCE_HES,
  IMPORT_SOURCE_RLP,
} from "@ero/app-common/enums/parcelImport";
import { STATE } from "@ero/app-common/enums/state";
import { AttachFile } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { FileInput } from "Components/controls/fileInput";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RLPInput } from "./rlpInput";

export const StateInputs: React.FC<{
  state: STATE | undefined;
  importSource:
    | IMPORT_SOURCE_BAY
    | IMPORT_SOURCE_RLP
    | IMPORT_SOURCE_BW
    | IMPORT_SOURCE_HES
    | undefined;
  trimFilenames: boolean | undefined;
  disabled?: boolean;
}> = ({ state, trimFilenames = false, importSource, disabled }) => {
  const [t] = useTranslation();

  const stateInputs = useMemo(() => {
    switch (state) {
      case STATE.RLP:
        return (
          <RLPInput
            importSource={importSource as IMPORT_SOURCE_RLP}
            trimFilenames={trimFilenames}
            disabled={disabled}
          />
        );
      case STATE.BAY:
        return (
          <FileInput
            name="schlagDaten"
            disabled={disabled}
            label={t("parcels.upload.BAY.shape")}
            buttonlabel={t("parcels.upload.chooseFile")}
            accept={[".zip"]}
            starticon={<AttachFile />}
            trimfilenames={trimFilenames}
          />
        );
      case STATE.BW:
        return (
          <FileInput
            name="schlagDaten"
            disabled={disabled}
            label={t("parcels.upload.BW.shape")}
            buttonlabel={t("parcels.upload.chooseFile")}
            accept={[".zip"]}
            starticon={<AttachFile />}
            trimfilenames={trimFilenames}
          />
        );
      case STATE.HES:
        return (
          <FileInput
            name="schlagDaten"
            disabled={disabled}
            label={t("parcels.upload.HES.dataExportZipFile")}
            buttonlabel={t("parcels.upload.chooseFile")}
            accept={[".zip"]}
            starticon={<AttachFile />}
            trimfilenames={trimFilenames}
          />
        );
      default:
        return (
          <Alert severity="info">{t("parcels.upload.filePlaceholder")}</Alert>
        );
    }
  }, [state, importSource, trimFilenames, disabled, t]);

  return stateInputs;
};
