import { Lock, LockOpen } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";

type EventMenuProps = {
  eventId: number;
  locked: boolean;
  disabled: boolean;
};

export const EventLock: React.FC<EventMenuProps> = ({
  eventId,
  locked,
  disabled,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const lock = useCallback(
    () => {
      dispatch(
        sagaActions.updateEvent({ id: eventId, update: { locked: true } }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId],
  );

  const unlock = useCallback(
    () => {
      dispatch(
        sagaActions.updateEvent({ id: eventId, update: { locked: false } }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId],
  );

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (locked) {
        unlock();
      } else {
        lock();
      }
    },
    [lock, locked, unlock],
  );

  return (
    <Tooltip
      title={
        locked ? t("planningV2.buttons.unlock") : t("planningV2.buttons.lock")
      }
    >
      <IconButton
        sx={{ position: "absolute", top: 1, right: 1 }}
        size="small"
        onClick={handleClick}
        disabled={disabled}
      >
        {locked && (
          <Lock
            sx={(theme) => ({ color: theme.palette.primary.contrastText })}
          />
        )}
        {!locked && (
          <LockOpen
            sx={(theme) => ({ color: theme.palette.primary.contrastText })}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};
