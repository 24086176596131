import { MapV2, ZoomControlV2 } from "Components/map";
import { BottomControls } from "Components/map/components/controls";
import { useParcelCenter } from "Components/map/hooks/centerOfMass";
import { FormikValues } from "Components/parcelModal/validationConfig";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { DrawingControls } from "./components/drawing/drawingControls";
import { DrawingInfo } from "./components/drawing/drawingInfo";
import { GeoElements } from "./components/geoElements";
import { TransformationControls } from "./components/transformation/transformationControls";
import { TransformationInfo } from "./components/transformation/transformationInfo";
import {
  ParcelEditingContext,
  ParcelEditingContextProvider,
} from "./contexts/parcelEditingContext";
import { DRAWINGMODE } from "./utils";

export const ParcelMap: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();

  const parcelCenter = useParcelCenter({
    shape: values.shape,
    position: values.position,
  });

  const { companyData } = useSelector((store: AppState) => store.auth);

  const initialCenter = useMemo(
    () => parcelCenter ?? companyData.location,
    // do not add dependencies so the initial center is not recomputed each and every time lastCente changes
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ParcelEditingContextProvider>
      <ParcelEditingContext.Consumer>
        {({ mapRef, drawingMode }) => (
          <MapV2
            id="parcel-modal-map"
            options={{
              draggableCursor:
                drawingMode === DRAWINGMODE.MARKER ||
                (drawingMode === DRAWINGMODE.POLYGON && !values.isClosedPolygon)
                  ? "crosshair"
                  : "",
            }}
            center={initialCenter}
            onLoad={(mapInstance: google.maps.Map) => {
              if (mapRef) mapRef.current = mapInstance;
            }}
            zoom={16}
          >
            <GeoElements />
            <DrawingInfo />
            <DrawingControls />
            <BottomControls>
              {drawingMode === DRAWINGMODE.POLYGON && (
                <>
                  <TransformationInfo />
                  <TransformationControls />
                </>
              )}
              <ZoomControlV2
                zoom={mapRef?.current?.getZoom() ?? 16}
                onZoomChanged={(zoom) => mapRef?.current?.setZoom(zoom)}
              />
            </BottomControls>
          </MapV2>
        )}
      </ParcelEditingContext.Consumer>
    </ParcelEditingContextProvider>
  );
};
