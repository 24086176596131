import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { Container, Grid2 } from "@mui/material";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValuesType } from "../../formConfig";
import { DateInputContent } from "./components/dateInputContent";
import { DayTimeContent } from "./components/dayTimeContent";

export const SelectDateScreen: React.FC = () => {
  const [t] = useTranslation();
  const { setFieldValue } = useFormikContext<ValuesType>();

  const dateOptions = useMemo(
    () => [
      { value: DateMode.FIXEDDATE, label: t("orders.createModal.fixedDate") },
      { value: DateMode.DATERANGE, label: t("orders.createModal.dateRange") },
    ],
    [t],
  );

  const timeOptions = useMemo(
    () => [
      {
        value: DateTimeMode.WITHOUTTIME,
        label: t("orders.createModal.withoutTime"),
      },
      {
        value: DateTimeMode.WITHTIME,
        label: t("orders.createModal.withTime"),
      },
      {
        value: DateTimeMode.WITHDAYTIME,
        label: t("orders.createModal.withDayTime"),
      },
    ],
    [t],
  );

  const resetTimes = useCallback(() => {
    setFieldValue("dateRestrictions.dayTime.startTime", undefined);
    setFieldValue("dateRestrictions.dayTime.endTime", undefined);
  }, [setFieldValue]);

  const resetDates = useCallback(() => {
    setFieldValue("dateRestrictions.date", undefined);
    setFieldValue("dateRestrictions.startDate", undefined);
    setFieldValue("dateRestrictions.endDate", undefined);
    setFieldValue("dateRestrictions.timeMode", undefined);
    resetTimes();
  }, [resetTimes, setFieldValue]);

  const onDateModeChange = useCallback(
    (val) => val && resetDates(),
    [resetDates],
  );

  const onTimeModeChange = useCallback(
    (val) => val && resetTimes(),
    [resetTimes],
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Grid2 container spacing={4}>
        <Grid2 size={6}>
          <StaticSelectWithAutoComplete
            name="dateRestrictions.dateMode"
            label={t("orders.createModal.dateMode")}
            required
            options={dateOptions}
            onChange={onDateModeChange}
            nullable
          />
        </Grid2>
        <Grid2 size={6}>
          <StaticSelectWithAutoComplete
            name="dateRestrictions.timeMode"
            label={t("orders.createModal.timeMode")}
            required
            options={timeOptions}
            onChange={onTimeModeChange}
            nullable
          />
        </Grid2>
        <Grid2 container spacing={4} size={12}>
          {<DateInputContent />}
        </Grid2>
        <Grid2 container spacing={4} size={12}>
          {<DayTimeContent />}
        </Grid2>
      </Grid2>
    </Container>
  );
};
