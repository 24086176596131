import {
  DragEndEvent,
  DragOverEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { sagaActions } from "Store/orderDetails/orderDetailsSagaActions";
import { OrderDetailsStateEvents } from "Store/orderDetails/orderDetailsSlice";
import { SORTABLE_CONTEXT } from "./jobsList";

export const useDragging = (
  order: OrderResponseBody | undefined,
  events: OrderDetailsStateEvents,
  plannedJobs: JobResponseBody[],
  unplannedJobs: JobResponseBody[],
) => {
  const dispatch = useDispatch();

  const [currentlyDraggedJob, setCurrentlyDraggedJob] = useState<
    JobResponseBody | undefined
  >(undefined);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
  );

  const allJobIds = useMemo(
    () => [
      ...events
        .map((event) => event.jobs)
        .flat()
        .map((job) => job._id),
      ...plannedJobs.map((job) => job._id),
      ...unplannedJobs.map((job) => job._id),
    ],
    [events, plannedJobs, unplannedJobs],
  );

  const updateEventJobs = useCallback((eventId: number, jobs: number[]) => {
    dispatch(
      sagaActions.updateEvent({
        id: eventId,
        update: {
          jobs,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteEvent = useCallback((eventId: number) => {
    dispatch(
      sagaActions.deleteEvent({
        id: eventId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragStart = useCallback((e: DragOverEvent) => {
    setCurrentlyDraggedJob(e.active.data.current as JobResponseBody);
  }, []);

  const onDragEnd = useCallback(
    async (e: DragEndEvent) => {
      if (!e.over) {
        return;
      }

      setCurrentlyDraggedJob(undefined);

      const currentContainerId = e.active.data.current?.sortable?.containerId;
      const overContainerId = e.over?.data.current?.sortable?.containerId;

      if (e.active.id !== e.over.id) {
        const oldIndex = allJobIds.indexOf(e.active.id as number);
        const newIndex = allJobIds.indexOf(e.over.id as number);
        const sortedJobIds = arrayMove(allJobIds, oldIndex, newIndex);

        const jobId = e.active.data.current?._id ?? undefined;
        const eventId = e.active.data.current?.eventId ?? undefined;
        const event = events.find((event) => event._id === eventId);

        if (event) {
          if (
            overContainerId !== currentContainerId &&
            currentContainerId !== SORTABLE_CONTEXT.UNPLANNED &&
            overContainerId === SORTABLE_CONTEXT.UNPLANNED
          ) {
            const eventJobs = event.jobs.map((job) => job._id);

            const filteredJobs = eventJobs.filter((id) => id !== jobId);
            if (filteredJobs.length === 0) {
              deleteEvent(event._id);
            } else {
              updateEventJobs(event._id, filteredJobs);
            }
          } else {
            const sortedJobs = sortedJobIds.filter((id) =>
              event.jobs.map((job) => job._id).includes(id),
            );

            updateEventJobs(eventId, sortedJobs);
          }
        } else if (order) {
          const eventJobs =
            events
              .map((event) => event.jobs)
              .flat()
              .map((job) => job._id) || [];
          const plannedJobsIds = plannedJobs.map((j) => j._id);
          const jobs = [...eventJobs, ...plannedJobsIds, ...sortedJobIds];

          dispatch(
            sagaActions.updateOrder({
              jobs,
            }),
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allJobIds, events, order, plannedJobs, updateEventJobs, deleteEvent],
  );

  return { currentlyDraggedJob, sensors, onDragStart, onDragEnd };
};
