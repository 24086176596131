import { Done } from "@mui/icons-material";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ClearIcon from "@mui/icons-material/Clear";
import MergeIcon from "@mui/icons-material/Merge";
import { Fab, Tooltip } from "@mui/material";
import { FormikValues } from "Components/parcelModal/validationConfig";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParcelEditingContext } from "../../contexts/parcelEditingContext";
import { POLYGON_TRANSFORMATION_MODE } from "../../utils";

export const TransformationControls: React.FC = () => {
  const [t] = useTranslation();

  const { values } = useFormikContext<FormikValues>();

  const {
    transformationMode,
    setTransformationMode,
    mergeParcels,
    splitParcel,
  } = useParcelEditingContext();

  const cancelOperation = useCallback(() => {
    setTransformationMode(POLYGON_TRANSFORMATION_MODE.NONE);
  }, [setTransformationMode]);

  const enableMergeMode = useCallback(() => {
    setTransformationMode(POLYGON_TRANSFORMATION_MODE.MERGE);
  }, [setTransformationMode]);

  const enableSplitMode = useCallback(() => {
    setTransformationMode(POLYGON_TRANSFORMATION_MODE.SPLIT);
  }, [setTransformationMode]);

  return (
    <>
      {values.parcelId && (
        <>
          {transformationMode === POLYGON_TRANSFORMATION_MODE.NONE && (
            <>
              <Tooltip title={t("parcels.upload.buttons.mergeParcel")}>
                <span>
                  <Fab
                    disabled={!values.isClosedPolygon || !values.shape}
                    onClick={enableMergeMode}
                  >
                    <MergeIcon />
                  </Fab>
                </span>
              </Tooltip>
              <Tooltip title={t("parcels.upload.buttons.splitParcel")}>
                <span>
                  <Fab
                    disabled={!values.isClosedPolygon || !values.shape}
                    onClick={enableSplitMode}
                  >
                    <CallSplitIcon />
                  </Fab>
                </span>
              </Tooltip>
            </>
          )}
          {transformationMode !== POLYGON_TRANSFORMATION_MODE.NONE && (
            <>
              <Tooltip title={t("general.buttons.cancel")}>
                <Fab onClick={cancelOperation}>
                  <ClearIcon />
                </Fab>
              </Tooltip>
              <Tooltip title={t("general.buttons.save")}>
                <Fab
                  color="success"
                  onClick={
                    transformationMode === POLYGON_TRANSFORMATION_MODE.MERGE
                      ? mergeParcels
                      : splitParcel
                  }
                >
                  <Done />
                </Fab>
              </Tooltip>
            </>
          )}
        </>
      )}
    </>
  );
};
