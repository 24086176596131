import { GRAPEVARIETYCOLOR } from "@ero/app-common/enums";
import { Chip, Grid2, Tooltip } from "@mui/material";
import React, { useMemo } from "react";

type GrapeVarietiesProps = {
  grapeVarieties:
    | {
        name: string;
        color: GRAPEVARIETYCOLOR;
        count: number;
      }[]
    | undefined;
};

export const GrapeVarieties: React.FC<GrapeVarietiesProps> = ({
  grapeVarieties,
}) => {
  const grapeVarietyChips = useMemo(() => {
    const grapeVarietiesSorted = grapeVarieties?.toSorted(
      // sort decending by count
      (a, b) => b.count - a.count,
    );
    const chips = grapeVarietiesSorted?.slice(0, 3).map((grapeVariety) => (
      <Grid2 key={grapeVariety.name}>
        <Chip
          label={`${grapeVariety.count} ${grapeVariety.name?.split("(")[0]}`}
          size="small"
          variant="outlined"
          sx={(theme) => ({
            ...(grapeVariety.color === GRAPEVARIETYCOLOR.RED
              ? {
                  backgroundColor: theme.palette.grapeVariety.red,
                  color: theme.palette.primary.contrastText,
                }
              : {
                  backgroundColor: theme.palette.grapeVariety.white,
                  color: theme.palette.text.primary,
                }),
          })}
        />
      </Grid2>
    ));
    if (
      grapeVarietiesSorted !== undefined &&
      grapeVarietiesSorted?.length > 3
    ) {
      chips?.push(
        <Grid2 key="other-grapevarieties">
          <Tooltip
            title={grapeVarietiesSorted
              ?.slice(3)
              .map(
                (grapeVariety) =>
                  `${grapeVariety.count} ${grapeVariety.name?.split("(")[0]}`,
              )
              .join(", ")}
          >
            <Chip
              size="small"
              variant="outlined"
              label={`+ ${grapeVarietiesSorted?.length - 3}`}
              sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
              })}
            />
          </Tooltip>
        </Grid2>,
      );
    }
    return chips;
  }, [grapeVarieties]);

  return grapeVarietyChips;
};
