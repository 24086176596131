import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { getParcels } from "Api";
import { FormikValues } from "Components/parcelModal/validationConfig";
import { useFormikContext } from "formik";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PolygonWithSize } from "Utils/turf/types";
import { useDrawing } from "../hooks/useDrawing";
import { useTransformation } from "../hooks/useTransformation";
import { DRAWINGMODE, POLYGON_TRANSFORMATION_MODE } from "../utils";

type ContextType = {
  drawingMode: DRAWINGMODE | undefined;
  setDrawingMode: Dispatch<SetStateAction<DRAWINGMODE | undefined>>;
  transformationMode: POLYGON_TRANSFORMATION_MODE;
  setTransformationMode: Dispatch<SetStateAction<POLYGON_TRANSFORMATION_MODE>>;
  handleMapClick: (e: google.maps.MapMouseEvent) => void;
  handleMarkerDrag: (e: google.maps.MapMouseEvent) => void;
  handlePolyVertexDrag: (e: google.maps.MapMouseEvent, index: number) => void;
  handlePolyVertexDragEnd: (
    e: google.maps.MapMouseEvent,
    index: number,
  ) => void;
  parcelIdToMerge: number | undefined;
  setParcelIdToMerge: Dispatch<SetStateAction<number | undefined>>;
  splitLineRef: React.MutableRefObject<google.maps.Polyline | null>;
  splitParcel: () => void;
  onParcelSplit: (splittedParcel: PolygonWithSize) => void;
  mergeParcels: () => void;
  onParcelMerge: (mergedParcelId: number) => void;
  allParcelsOfCustomer: ParcelsResponseBody["data"] | undefined;
  mapRef: React.MutableRefObject<google.maps.Map | null> | undefined;
};

export const ParcelEditingContext = createContext<ContextType>({
  drawingMode: undefined,
  setDrawingMode: () => {},
  transformationMode: POLYGON_TRANSFORMATION_MODE.NONE,
  setTransformationMode: () => {},
  handleMapClick: () => {},
  handleMarkerDrag: () => {},
  handlePolyVertexDrag: () => {},
  handlePolyVertexDragEnd: () => {},
  parcelIdToMerge: undefined,
  setParcelIdToMerge: () => {},
  splitLineRef: React.createRef(),
  splitParcel: () => {},
  onParcelSplit: () => {},
  mergeParcels: () => {},
  onParcelMerge: () => {},
  allParcelsOfCustomer: undefined,
  mapRef: undefined,
});

export const useParcelEditingContext = () => useContext(ParcelEditingContext);

export const ParcelEditingContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const mapRef = useRef<google.maps.Map>(null);

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const [allParcelsOfCustomer, setAllParcelsOfCustomer] = useState<
    ParcelsResponseBody["data"] | undefined
  >();

  useEffect(() => {
    const fetchAllParcelsOfCustomer = async () => {
      if (values.customer) {
        const parcels = await getParcels({
          customer: values.customer,
          limit: 99999,
        });
        setAllParcelsOfCustomer(parcels.data);
      }
    };

    fetchAllParcelsOfCustomer();
  }, [values.customer]);

  const [drawingMode, setDrawingMode] = useState<DRAWINGMODE | undefined>(
    values.position ? DRAWINGMODE.MARKER : DRAWINGMODE.POLYGON,
  );

  const [transformationMode, setTransformationMode] =
    useState<POLYGON_TRANSFORMATION_MODE>(POLYGON_TRANSFORMATION_MODE.NONE);

  const [parcelIdToMerge, setParcelIdToMerge] = useState<number | undefined>();

  const splitLineRef = useRef<google.maps.Polyline | null>(null);

  const onParcelSplit = useCallback(
    async (splittedParcel: PolygonWithSize) =>
      await setFieldValue(
        "splittedParcels",
        values.splittedParcels
          ? [...(values.splittedParcels ?? []), splittedParcel]
          : [splittedParcel],
      ),
    [setFieldValue, values.splittedParcels],
  );

  const onParcelMerge = useCallback(
    async (mergedParcelId: number) =>
      await setFieldValue(
        "mergedParcelIds",
        values.mergedParcelIds
          ? [...(values.mergedParcelIds ?? []), mergedParcelId]
          : [mergedParcelId],
      ),
    [setFieldValue, values.mergedParcelIds],
  );

  const { mergeParcels, splitParcel } = useTransformation(
    setTransformationMode,
    parcelIdToMerge,
    setParcelIdToMerge,
    splitLineRef,
    onParcelSplit,
    onParcelMerge,
    allParcelsOfCustomer,
    values,
    setFieldValue,
  );

  const {
    handleMapClick,
    handleMarkerDrag,
    handlePolyVertexDrag,
    handlePolyVertexDragEnd,
  } = useDrawing(
    drawingMode,
    transformationMode,
    mapRef,
    values,
    setFieldValue,
  );

  return (
    <ParcelEditingContext.Provider
      value={{
        drawingMode,
        setDrawingMode,
        transformationMode,
        setTransformationMode,
        handleMapClick,
        handleMarkerDrag,
        handlePolyVertexDrag,
        handlePolyVertexDragEnd,
        parcelIdToMerge,
        setParcelIdToMerge,
        splitLineRef,
        splitParcel,
        onParcelSplit,
        mergeParcels,
        onParcelMerge,
        allParcelsOfCustomer,
        mapRef,
      }}
    >
      {children}
    </ParcelEditingContext.Provider>
  );
};
