import {
  IMPORT_SOURCE_BAY,
  IMPORT_SOURCE_BW,
  IMPORT_SOURCE_HES,
  IMPORT_SOURCE_RLP,
} from "@ero/app-common/enums/parcelImport";
import { STATE } from "@ero/app-common/enums/state";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";
import { FieldImportValuesType } from "Types";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

const rlpImportOptions = [
  { value: IMPORT_SOURCE_RLP.FLORLP, label: "FLOrlp" },
  { value: IMPORT_SOURCE_RLP.LEA, label: "LEA" },
];
const bwImportOptions = [{ value: IMPORT_SOURCE_BW.FIONA, label: "FIONA" }];
const bavImportOptions = [{ value: IMPORT_SOURCE_BAY.IBALIS, label: "iBALIS" }];
const hesImportOptions = [
  { value: IMPORT_SOURCE_HES.AGRARPORTAL, label: "Agrarportal" },
];

const ImportSourceSelectionComponent: React.ForwardRefRenderFunction<
  HTMLInputElement
> = (props, ref) => {
  const [t] = useTranslation();

  const { values, setFieldValue } = useFormikContext<FieldImportValuesType>();
  const importOptions = useMemo(() => {
    switch (values.state) {
      case STATE.BAY:
        return bavImportOptions;
      case STATE.BW:
        return bwImportOptions;
      case STATE.RLP:
        return rlpImportOptions;
      case STATE.HES:
        return hesImportOptions;
      default:
        return [];
    }
  }, [values.state]);

  useEffect(() => {
    if (values.state) {
      switch (values.state) {
        case STATE.BAY:
          setFieldValue("type", IMPORT_SOURCE_BAY.IBALIS);
          break;
        case STATE.BW:
          setFieldValue("type", IMPORT_SOURCE_BW.FIONA);
          break;
        case STATE.RLP:
          setFieldValue("type", IMPORT_SOURCE_RLP.FLORLP);
          break;
        case STATE.HES:
          setFieldValue("type", IMPORT_SOURCE_HES.AGRARPORTAL);
          break;
        default:
          setFieldValue("type", "");
      }
    }
  }, [setFieldValue, values.state]);

  return (
    <StaticSelectWithAutoComplete
      {...props} // required for tooltip
      ref={ref}
      name="type"
      options={importOptions}
      disabled={values.state !== STATE.RLP}
      required
      sx={{ mt: 1, mb: 1 }}
      label={t("parcels.upload.importType") as string}
    />
  );
};

export const ImportSourceSelection = React.forwardRef(
  ImportSourceSelectionComponent,
);
ImportSourceSelection.displayName = "ImportSourceSelection";
