import { Add } from "@mui/icons-material";
import { Box, Fab, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type IDriverPlaceholder = {
  onAddBtnClick: () => void;
};

export const DriverPlaceholder: React.FC<IDriverPlaceholder> = ({
  onAddBtnClick,
}) => {
  const [t] = useTranslation();

  return (
    <Box
      id="overlay"
      width={"100%"}
      height={"100%"}
      position={"absolute"}
      zIndex={10}
      sx={{ backgroundColor: "#00000094" }}
    >
      <Box mt={4} textAlign="center">
        <Fab color="primary" size="small" onClick={onAddBtnClick}>
          <Add />
        </Fab>
        <Typography variant="h6" paddingTop={2} color={"white"}>
          {t("components.calendar.noDriversTitle")}
        </Typography>
        <Typography variant="body1" color={"white"}>
          {t("components.calendar.noDriversText")}
        </Typography>
      </Box>
    </Box>
  );
};
