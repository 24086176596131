import { ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Box, SvgIcon, Theme, Tooltip } from "@mui/material";
import { OverlayView } from "@react-google-maps/api";
import { isParcelWithPosition } from "Components";
import { useParcelCenter } from "Components/map/hooks/centerOfMass";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useJobIndex } from "Screens/order-details/hooks/useJobIndex";
import { sagaActions } from "Store/orderDetails/orderDetailsSagaActions";
import { getJobStatusColor, getJobTextColor } from "Utils";
import { JobDetailsModal } from "../jobDetailsModal";

const styles = {
  markerContainer: {
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "14px",
    fontWeight: "bold",
  },
};

interface IJobOverlay {
  index: number;
  parcel: ParcelResponseBody;
  job: ParcelResponseBody["jobs"][number];
  isVisible: boolean;
  disableJobDetails?: boolean;
}

const JobOverlay: React.FC<IJobOverlay> = ({
  index,
  job,
  parcel,
  isVisible,
  disableJobDetails = false,
}) => {
  const dispatch = useDispatch();

  const jobIndex = useJobIndex(job._id);

  const center = useParcelCenter(parcel);
  const offset = index * 25;

  const [selectedJobId, setSelectedJobId] = useState<number>();
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] =
    useState<boolean>(false);

  const jobStatusColor = useMemo(
    () => getJobStatusColor(job.status).split(".")[1] ?? "default",
    [job.status],
  );

  const jobTextColor = useMemo(() => {
    return (theme: Theme) => getJobTextColor(jobStatusColor, theme);
  }, [jobStatusColor]);

  const showJobDetails = useCallback(
    (id: number) => {
      if (!disableJobDetails) {
        setSelectedJobId(id);
        dispatch(sagaActions.getJobTelemtry(id));
        setIsJobDetailsModalOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!isVisible) {
    return "";
  }

  return (
    <>
      <JobDetailsModal
        isOpen={isJobDetailsModalOpen}
        onClose={() => setIsJobDetailsModalOpen(false)}
        jobId={selectedJobId}
      ></JobDetailsModal>
      <OverlayView
        position={center}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Box
          sx={{
            ...styles.markerContainer,
            transform: isParcelWithPosition(parcel)
              ? `translate(${offset}px, -70px)`
              : `translateX(${offset}px)`,
          }}
          onClick={() => showJobDetails(job._id)}
        >
          <Tooltip
            title={job.name}
            placement="top"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <Box sx={styles.iconContainer}>
              <SvgIcon
                fontSize="large"
                sx={(theme) => ({
                  fill: theme.palette.status[jobStatusColor],
                })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="none"
                >
                  <g>
                    <path d="M0,0h24v24H0V0z" fill="none" />
                  </g>
                  <g>
                    <path d="M12,2c-4.2,0-8,3.22-8,8.2c0,3.32,2.67,7.25,8,11.8c5.33-4.55,8-8.48,8-11.8C20,5.22,16.2,2,12,2z M12,12c-1.1,0,2,0.9,2,2C14,11.1,13.1,12,12,12z" />
                  </g>
                </svg>
              </SvgIcon>
              <Box
                component="span"
                sx={(theme) => ({
                  ...styles.text,
                  color: jobTextColor(theme),
                })}
              >
                {jobIndex}
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </OverlayView>
    </>
  );
};

export default JobOverlay;
