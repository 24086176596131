import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Button, Divider, Grid2, Stack, Typography } from "@mui/material";
import logo from "Assets/logo.svg";
import { AvatarUpload } from "Components";
import { useUserTitle } from "Hooks/userTitle";
import { type AppState } from "Store";
import { resetMeta, updateCompanyData, updateUserData } from "Store/auth";
import { resizeTo } from "Utils";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CompanyInformation } from "./components/companyInformation/companyInformation";
import { UserInformation } from "./components/userInformation/userInformation";

export const Profile: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { success, loading, userData, companyData } = useSelector(
    (state: AppState) => state.auth,
  );

  const userTitle = useUserTitle(userData);

  const onUpdate = useCallback((valueObj: { [name: string]: string }) => {
    dispatch(updateUserData(valueObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCompanyAvatarUpdate = useCallback(
    (valueObj: { [name: string]: string }) => {
      dispatch(updateCompanyData(valueObj));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleAvatarUpload = useCallback(
    (value: File) => {
      const resizedImage = resizeTo(value, 150);
      resizedImage.then((image: string) => {
        onUpdate({ avatar: image });
      });
    },
    [onUpdate],
  );

  const handleCompanyAvatarUpload = useCallback(
    (value: File) => {
      const resizedImage = resizeTo(value, 150);
      resizedImage.then((image: string) => {
        onCompanyAvatarUpdate({ avatar: image });
      });
    },
    [onCompanyAvatarUpdate],
  );

  useEffect(() => {
    return (): void => {
      dispatch(resetMeta());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(resetMeta());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Grid2 container spacing={4} pt={2} alignItems={"flex-start"}>
      <Grid2 textAlign={"center"} size={2} mt={8}>
        <AvatarUpload
          name={"user"}
          src={userData.avatar ?? undefined}
          loading={loading}
          onSubmit={handleAvatarUpload}
          alt={userTitle}
        />
      </Grid2>
      <Grid2 size={9}>
        <UserInformation />
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Grid2 textAlign={"center"} size={2} mt={8}>
        <AvatarUpload
          name={"company"}
          src={companyData.avatar ?? "n/a"}
          alt={companyData.name}
          loading={loading}
          onSubmit={handleCompanyAvatarUpload}
        />
      </Grid2>
      <Grid2 size={9}>
        <CompanyInformation />
      </Grid2>
      <Grid2 mt={2} size={12}>
        <Stack alignItems="center" spacing={2}>
          <img width={84} src={logo} alt="contacts" />
          <Typography>{t("general.contactsText1")}</Typography>
          <Typography>{t("general.contactsText2")}</Typography>
          <Button
            startIcon={<LocalPhoneIcon />}
            href={"tel:" + import.meta.env.VITE_ERO_SERVICE_PHONE}
          >
            {import.meta.env.VITE_ERO_SERVICE_PHONE}
          </Button>
        </Stack>
      </Grid2>
    </Grid2>
  );
};
