import { GRAPEVARIETYCOLOR } from "@ero/app-common/enums";
import { OrderDateType } from "@ero/app-common/models";
import { Grid2, Typography } from "@mui/material";
import { EllipsisText } from "Components";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { useEventDraggingContext } from "../../contexts/EventDraggingContext";
import { violatesDateRestrictions } from "../../utils/eventsOrderCollisionInfo";
import { Flags } from "./components/flags";
import { Gemarkungen } from "./components/gemarkungen";
import { GrapeVarieties } from "./components/grapeVarieties";
import { OrderName } from "./components/ordername";
import { ParcelSizeAndCount } from "./components/parcelSizeAndCount";
import { Time } from "./components/time";

type EventContentProps = {
  title?: string;
  orderInfo?: {
    _id: number;
    name: string;
    grapeVarieties: {
      name: string;
      color: GRAPEVARIETYCOLOR;
      count: number;
    }[];
    customer: { companyName: string };
    parcelsSize: number | undefined;
    parcelsCount: number | undefined;
    gemarkungen: string[];
    notes: string;
    dateRestrictions: OrderDateType;
  };
  start?: Date | null;
  end?: Date | null;
  dateRestrictions?: string;
  eventLocked?: boolean;
  showAllInfo?: boolean;
  selected?: boolean;
  containerHeight?: number;
};

export const EventContent = React.forwardRef<HTMLDivElement, EventContentProps>(
  (
    {
      title,
      orderInfo,
      start,
      end,
      dateRestrictions,
      eventLocked = false,
      showAllInfo = false,
      selected = false,
      containerHeight,
      ...props
    },
    ref,
  ) => {
    const currentCalendarDate = useSelector(
      (state: AppState) => state.planningV2.date,
    );
    const { activeEventOrderId: activelyDraggedEventOrderId } =
      useEventDraggingContext();

    const showCustomer = useMemo(
      () => (containerHeight && containerHeight > 60) || showAllInfo,
      [containerHeight, showAllInfo],
    );

    const showParcelSizeAndCount = useMemo(
      () => (containerHeight && containerHeight > 70) || showAllInfo,
      [containerHeight, showAllInfo],
    );

    const showTime = useMemo(
      () => (containerHeight && containerHeight > 85) || showAllInfo,
      [containerHeight, showAllInfo],
    );

    const showGemarkungen = useMemo(
      () => (containerHeight && containerHeight > 110) || showAllInfo,
      [containerHeight, showAllInfo],
    );

    const showGrapeVarieties = useMemo(
      () => (containerHeight && containerHeight > 170) || showAllInfo,
      [containerHeight, showAllInfo],
    );
    const showNotes = useMemo(
      () => (containerHeight && containerHeight > 200) || showAllInfo,
      [containerHeight, showAllInfo],
    );

    const eventIsMoving = useMemo(
      () =>
        activelyDraggedEventOrderId !== undefined &&
        activelyDraggedEventOrderId === orderInfo?._id,
      [activelyDraggedEventOrderId, orderInfo?._id],
    );

    const eventViolatesOrderDateRestrictions = useMemo(
      () =>
        !eventLocked &&
        (violatesDateRestrictions(
          currentCalendarDate,
          orderInfo?.dateRestrictions,
          start,
          end,
        ) ??
          false),
      [
        currentCalendarDate,
        end,
        eventLocked,
        orderInfo?.dateRestrictions,
        start,
      ],
    );

    return (
      <Grid2
        ref={ref}
        {...props}
        container
        sx={{
          p: 0.5,
          height: "100%",
          overflow: "hidden",
          alignContent: "flex-start",
          width: showAllInfo ? "100%" : undefined,
          justifyContent: "space-between",
        }}
      >
        {showTime && <Time start={start ?? undefined} end={end ?? undefined} />}
        <Grid2
          size={11}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flags
            eventViolatesOrderDateRestrictions={
              eventViolatesOrderDateRestrictions
            }
            eventIsMoving={eventIsMoving}
            showAllInfo={showAllInfo}
            selected={selected}
          />
          <OrderName
            orderName={orderInfo?.name ?? title ?? ""}
            showAllInfo={showAllInfo}
          />
        </Grid2>
        {showCustomer && (
          <Grid2 size={12}>
            <Typography variant="subtitle2">
              {orderInfo?.customer?.companyName}
            </Typography>
          </Grid2>
        )}
        {showParcelSizeAndCount && (
          <ParcelSizeAndCount
            parcelsCount={orderInfo?.parcelsCount}
            parcelsSize={orderInfo?.parcelsSize}
          />
        )}
        <Grid2 container spacing={0.5}>
          {showGemarkungen && (
            <Gemarkungen gemarkungen={orderInfo?.gemarkungen} />
          )}
          {showGrapeVarieties && (
            <Grid2 container spacing={0.5} size={12}>
              <GrapeVarieties grapeVarieties={orderInfo?.grapeVarieties} />
            </Grid2>
          )}
          {dateRestrictions && (
            <Grid2 size={12}>
              <Typography variant="caption">{dateRestrictions}</Typography>
            </Grid2>
          )}
          {showNotes && (
            <Grid2 size={12}>
              <EllipsisText
                text={orderInfo?.notes || ""}
                maxLines={1}
              ></EllipsisText>
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    );
  },
);

EventContent.displayName = "EventContent";
