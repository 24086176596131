import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Event } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListSubheader,
} from "@mui/material";
import { ROUTES } from "Constants";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { DriverMachineInfo } from "./components/driverMachineInfo/driverMachineInfo";
import { TimeInfo } from "./components/timeInfo/timeInfo";

interface IPlanningCard {
  job: JobResponseBody | undefined;
}

export const PlanningCard: React.FC<IPlanningCard> = ({ job }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { order, events } = useSelector(
    (state: AppState) => state.orderDetails,
  );
  const jobEvent = useMemo(
    () => events.find((e) => e.jobs.find((j) => j._id === job?._id)),
    [job?._id, events],
  );

  const navigateToPlanning = useCallback(() => {
    if (!order || !job) return;

    dispatch(sagaActions.prepareEventReveal({ job, order, jobEvent }));

    navigate(ROUTES.MAIN.PLANNING2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, job, jobEvent, navigate]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <List>
          <ListSubheader>Bearbeitet von</ListSubheader>
          <DriverMachineInfo
            jobEmployees={job?.employees}
            jobMachine={job?.machine}
          />
          <ListSubheader> {t("general.labels.time")}</ListSubheader>
          <TimeInfo jobStart={job?.start} jobEnd={job?.end} />
        </List>
      </CardContent>
      <CardActions>
        <Button
          color="inherit"
          onClick={navigateToPlanning}
          startIcon={<Event />}
        >
          {t("components.calendar.reschedule")}
        </Button>
      </CardActions>
    </Card>
  );
};
