import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2,
  LinearProgress,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { MapCard, OverviewCard, TelemetryCard } from "./components";
import { PlanningCard } from "./components/planningCard/planningCard";
import { StatusCard } from "./components/statusCard/statusCard";

interface IJobDetailsModal {
  isOpen: boolean;
  onClose: () => void;
  jobId?: number;
}

export const JobDetailsModal: React.FC<IJobDetailsModal> = ({
  isOpen,
  onClose,
  jobId,
}) => {
  const allOrdersJobs = useSelector(
    (state: AppState) => state.orderDetails.order?.jobDetails?.jobs,
  );
  const jobLoading = useSelector(
    (state: AppState) => state.orderDetails.jobLoading,
  );

  const job = useMemo(
    () => allOrdersJobs?.find((job) => job._id === jobId),
    [allOrdersJobs, jobId],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      sx={{ height: "95vh" }}
    >
      <DialogTitle>
        {job?.name} - {job?.parcel.name}
        {jobLoading && <LinearProgress />}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <DialogContent dividers>
        <Grid2 container spacing={2}>
          <Grid2
            size={{
              xs: 12,
              sm: 3,
            }}
          >
            <OverviewCard job={job} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 3,
            }}
          >
            <PlanningCard job={job} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 3,
            }}
          >
            <StatusCard statusOperations={job?.statusOperations} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 3,
            }}
          >
            {job && <TelemetryCard jobId={job?._id} />}
          </Grid2>
          <Grid2 size={12}>
            <MapCard job={job} />
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
};
