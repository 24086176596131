import { STATE } from "@ero/app-common/enums/state";
import { OptionType } from "Types";
import { sortByLabel } from "Utils/options";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useStateOptions = () => {
  const [t] = useTranslation();

  const states = useMemo(() => {
    const states: OptionType[] = [];
    for (const state in STATE) {
      states.push({
        value: state,
        label: t(`parcels.upload.states.${state}`),
      });
    }
    return states.sort(sortByLabel);
  }, [t]);

  return states;
};
