import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { Draggable } from "@fullcalendar/interaction";
import { PendingActions } from "@mui/icons-material";
import { Grid2, IconButton, Paper } from "@mui/material";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EventContent } from "Screens/planningV2/components/eventContent/eventContent";
import { useEventDraggingContext } from "Screens/planningV2/contexts/EventDraggingContext";
import { getBusinessHours } from "Screens/planningV2/utils/eventsOrderCollisionInfo";
import { AppState } from "Store";
import { displayDateRestriction } from "Utils/date";

type OrderItemProps = {
  order: OrderResponseBody<number[]>;
  highlight: boolean;
  onDetailsBtnClick: (id: number) => void;
};

export const OrderItem: React.FC<OrderItemProps> = ({
  order,
  highlight,
  onDetailsBtnClick,
}) => {
  const [t] = useTranslation();

  const { setActive, setInactive } = useEventDraggingContext();

  const { fullscreenContainer } = useFullscreenContext();

  const itemRef = useRef<HTMLDivElement>(null);
  const draggableRef = useRef<Draggable | null>(null);

  const [paperRef, setPaperRef] = useState<HTMLDivElement | null>(null);

  const currentCalendarDate = useSelector(
    (store: AppState) => store.planningV2.date,
  );

  const orderInformation = useMemo(() => {
    return {
      _id: order._id,
      name: order.name,
      notes: order.notes ?? "",
      customer: order.customer,
      parcelsCount: order.jobDetails?.parcelCount,
      parcelsSize: order.jobDetails?.parcelSize,
      grapeVarieties: order.jobDetails?.grapeVarieties ?? [],
      gemarkungen: order.jobDetails?.gemarkungen ?? [],
      jobs: order.jobDetails?.jobs,
      dateRestrictions: order.dateRestrictions,
    };
  }, [
    order._id,
    order.name,
    order.notes,
    order.customer,
    order.jobDetails?.parcelCount,
    order.jobDetails?.parcelSize,
    order.jobDetails?.grapeVarieties,
    order.jobDetails?.gemarkungen,
    order.jobDetails?.jobs,
    order.dateRestrictions,
  ]);

  const dateRestrictionsContent = useMemo(
    () =>
      displayDateRestriction(
        order.dateRestrictions,
        t("orders.createModal.toDo"),
        t("orders.createModal.until"),
      ),
    [order.dateRestrictions, t],
  );

  const paperStyle = useMemo(
    () => ({
      width: "100%",
      p: 2,
      cursor: "grab",
      "&:active": {
        cursor: "grabbing",
      },
      mb: 3,
      position: "relative",
      border: highlight ? 1 : null,
      borderColor: highlight ? "primary.main" : null,
    }),
    [highlight],
  );

  useEffect(() => {
    if (paperRef && !draggableRef.current) {
      const eventsBusinessHours = getBusinessHours(
        currentCalendarDate,
        orderInformation.dateRestrictions,
      );

      draggableRef.current = new Draggable(paperRef, {
        eventData: {
          id: `${orderInformation._id}`,
          create: true,
          duration: order.remainingTime,
          extendedProps: { order: orderInformation },
        },
        appendTo: fullscreenContainer ?? undefined,
      });
      draggableRef.current.dragging.emitter.on("dragstart", () => {
        setActive(orderInformation._id, eventsBusinessHours);
      });
      draggableRef.current.dragging.emitter.on("dragend", setInactive);
      return () => {
        try {
          draggableRef.current?.destroy();
        } catch (e) {
          console.warn("Unable to destroy calendar draggable", e);
        }
      };
    }
  }, [
    currentCalendarDate,
    fullscreenContainer,
    order._id,
    order.dateRestrictions,
    order.dateRestrictions.dateMode,
    order.dateRestrictions.startDate,
    order.dateRestrictions.timeMode,
    order.end,
    order.name,
    order.remainingTime,
    order.start,
    orderInformation,
    paperRef,
    setActive,
    setInactive,
  ]);

  useEffect(() => {
    if (itemRef.current && highlight) {
      itemRef.current.scrollIntoView();
    }
  }, [highlight]);

  return (
    <Grid2 ref={itemRef} size={12}>
      <Paper sx={paperStyle} elevation={3} ref={setPaperRef}>
        <IconButton
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={() => onDetailsBtnClick(order._id)}
        >
          <PendingActions />
        </IconButton>
        <EventContent
          dateRestrictions={dateRestrictionsContent}
          orderInfo={orderInformation}
          showAllInfo
        />
      </Paper>
    </Grid2>
  );
};
