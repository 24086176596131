import { Person } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { ROUTES } from "Constants";
import { useUserTitle } from "Hooks/userTitle";
import { AppState } from "Store";
import { default as React, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface IProfileMenu {
  onLogoutClick: () => void;
}

export const ProfileMenu: React.FC<IProfileMenu> = ({ onLogoutClick }) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const anchorProfileMenuRef = useRef(null);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const { userData } = useSelector((state: AppState) => state.auth);

  const handleProfileMenuToggle = useCallback(() => {
    setProfileMenuOpen((prevOpen) => !prevOpen);
  }, []);

  const avatarAlt = useUserTitle(userData);

  return (
    <>
      <Tooltip
        title={t("general.navigation.profileMenu")}
        disableInteractive
        arrow
      >
        <IconButton
          ref={anchorProfileMenuRef}
          aria-controls={profileMenuOpen ? "profile-menu-list-grow" : undefined}
          aria-haspopup="true"
          aria-label="Profile menu"
          color="inherit"
          onClick={handleProfileMenuToggle}
        >
          <Avatar alt={avatarAlt} src={userData.avatar ?? undefined}>
            {!userData.avatar && <Person />}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Popper
        open={profileMenuOpen}
        anchorEl={anchorProfileMenuRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleProfileMenuToggle}>
                <MenuList
                  autoFocusItem={profileMenuOpen}
                  id="profile-menu-list-grow"
                >
                  <MenuItem
                    onClick={() => {
                      navigate(ROUTES.PROFILE.VIEW);
                      handleProfileMenuToggle();
                    }}
                  >
                    {t("general.navigation.profile") as string}
                  </MenuItem>
                  <MenuItem onClick={onLogoutClick}>
                    {t("general.navigation.logout") as string}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
