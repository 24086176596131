import { getCustomers } from "Api";
import { useMemo } from "react";
import { OptionType } from "Types";
import { sortByLabel } from "Utils/options";

const fetchCustomerOptions = async (): Promise<OptionType[]> => {
  try {
    const {
      data: { data },
    } = await getCustomers({
      params: { page: 0, pageLength: -1 },
      spec: [["companyName"], ["label"], ["email"], ["mainContact", "email"]],
    });
    return data
      .map((customer) => ({
        value: customer._id,
        label: `${customer.companyName}`,
        meta: { email: customer.email || customer.mainContact?.email || "" },
      }))
      .sort(sortByLabel);
  } catch {
    return [];
  }
};

export const useAllCustomerOptions = () => {
  return useMemo(
    () => ({
      fetchCustomerOptions,
    }),
    [],
  );
};
