import {
  convertAreaToCustom,
  UnitSymbol,
} from "@ero/app-common/util/convertArea";
import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { SqMeter } from "@ero/app-common/util/Units";
import { formatTime } from "@ero/app-common/v2/utils/dateTime";
import { alpha, Box, Divider, Grid2, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { AddOrderButton } from "./components/addOrderButton";
import { FiltersChips } from "./components/filter/components/filtersChips";
import { Filter } from "./components/filter/filter";
import { OrderList } from "./components/orderList/orderList";

export const Sidebar: React.FC = () => {
  const [t] = useTranslation();

  const selectedUnit = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = useState(false);

  const { orders } = useSelector((state: AppState) => state.planningV2);

  const showCreateOrderModal = useCallback(() => {
    setIsCreateOrderModalOpen(true);
  }, []);

  const closeCreateOrderModal = useCallback(() => {
    setIsCreateOrderModalOpen(false);
  }, []);

  const totalWorkTime = useMemo(
    () =>
      formatTime(
        orders.reduce(
          (acc, curr) => acc + curr.remainingTime,
          0,
        ) as Milliseconds,
        false,
      ) + " h",
    [orders],
  );

  const totalArea = useMemo(
    () =>
      convertAreaToCustom(
        orders.reduce(
          (acc, curr) => acc + (curr.jobDetails?.parcelSize ?? 0),
          0,
        ) as SqMeter,
        selectedUnit,
      ),
    [orders, selectedUnit],
  );

  return (
    <Box
      sx={(theme) => ({
        borderRight: 1,
        borderColor: theme.palette.divider,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        py: 1,
      })}
    >
      <Grid2
        container
        pb={1}
        px={4}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid2 size={10}>
          <Typography variant="button">
            {t("planningV2.sidebar.unplannedOrders", {
              count: orders.length,
            })}
          </Typography>
        </Grid2>
        <Grid2>
          <Filter />
        </Grid2>
      </Grid2>
      <FiltersChips />
      <Divider />
      <Box
        id="orderListContainer"
        style={{ height: "100%", overflowY: "hidden", position: "relative" }}
      >
        <Box style={{ overflowY: "scroll", height: "100%" }}>
          <OrderList showCreateOrderModal={showCreateOrderModal} />
        </Box>
        <AddOrderButton
          isCreateOrderModalOpen={isCreateOrderModalOpen}
          showCreateOrderModal={showCreateOrderModal}
          closeCreateOrderModal={closeCreateOrderModal}
        />
      </Box>
      <div
        id="dropzoneContainer"
        style={{ height: "100%", display: "none", flex: 1 }}
      >
        <Grid2 container padding={2} sx={{ height: "100%" }}>
          <div id="dropzone" style={{ height: "100%", width: "100%" }}>
            <Grid2
              size={12}
              sx={(theme) => ({
                borderStyle: "dashed",
                borderColor: alpha(theme.palette.primary.light, 0.6),
                p: 2,
                height: "100%",
              })}
            >
              <Typography variant="body2" textAlign={"center"}>
                {t("planningV2.dropZone")}
              </Typography>
            </Grid2>
          </div>
        </Grid2>
      </div>
      <Grid2 container rowSpacing={2}>
        <Grid2 size={12}>
          <Box borderTop={1} borderColor={"divider"} pt={2} px={4}>
            <Typography variant="body2">
              <b>{`${t("general.labels.totalArea")}: `}</b>
              {totalArea
                ? `${totalArea.toFixed(2)} ${UnitSymbol[selectedUnit]}`
                : `- ${UnitSymbol[selectedUnit]}`}
            </Typography>
            <Typography variant="body2">
              <b>{`${t("general.labels.totalWorktime")}: `}</b>
              {totalWorkTime}
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};
