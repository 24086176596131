import { JOBSTATUS } from "@ero/app-common/enums";
import { Marker, Polyline } from "@react-google-maps/api";
import { MapParcel } from "Components/map";
import { FormikValues } from "Components/parcelModal/validationConfig";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { markerIcons } from "Utils";
import { useParcelEditingContext } from "../contexts/parcelEditingContext";
import { DRAWINGMODE, lineSymbol, POLYGON_TRANSFORMATION_MODE } from "../utils";
import { SplitLine } from "./transformation/figures/splitLine";

export const GeoElements: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();

  const {
    drawingMode,
    transformationMode,
    allParcelsOfCustomer,
    parcelIdToMerge,
    setParcelIdToMerge,
    handleMapClick,
    handleMarkerDrag,
    handlePolyVertexDrag,
    handlePolyVertexDragEnd,
  } = useParcelEditingContext();

  const currentMarkerPosition = useMemo(
    () => values.position,
    [values.position],
  );

  const parcelMarker = useMemo(
    () =>
      drawingMode === DRAWINGMODE.MARKER &&
      currentMarkerPosition && (
        <Marker
          draggable
          position={currentMarkerPosition}
          onDrag={handleMarkerDrag}
          icon={{
            url: markerIcons[JOBSTATUS.OPEN],
            scaledSize: new google.maps.Size(36, 36),
          }}
        />
      ),
    [currentMarkerPosition, drawingMode, handleMarkerDrag],
  );

  const parcelPolygon = useMemo(
    () =>
      drawingMode === DRAWINGMODE.POLYGON && (
        <>
          {values.isClosedPolygon && (
            <>
              <MapParcel
                showPolygon
                fillColor={"#fcc419"}
                parcel={{
                  ...values,
                  shape: values.shape,
                  name: values.name || " ",
                }}
              />
              <SplitLine />
            </>
          )}
          <Polyline
            visible={!values.isClosedPolygon}
            path={values.shape || []}
            options={{
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
            }}
          />
          {transformationMode === POLYGON_TRANSFORMATION_MODE.NONE &&
            values.shape &&
            values.shape.map((coord, index) => {
              return (
                <Marker
                  draggable
                  icon={{
                    url: markerIcons[JOBSTATUS.OPEN],
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  onDrag={(e) => handlePolyVertexDrag(e, index)}
                  onDragEnd={(e) => handlePolyVertexDragEnd(e, index)}
                  key={index}
                  position={coord}
                  {...(index === 0
                    ? // Handle click on first marker to complete polygon
                      {
                        onClick: (e) => {
                          handleMapClick(e);
                        },
                      }
                    : // others are not clickable
                      { clickable: false })}
                />
              );
            })}
        </>
      ),
    [
      drawingMode,
      handleMapClick,
      handlePolyVertexDrag,
      handlePolyVertexDragEnd,
      transformationMode,
      values,
    ],
  );

  const splittedParcels = useMemo(
    () =>
      values.splittedParcels.map((splittedParcel, index) => (
        <MapParcel
          key={index}
          showPolygon
          fillColor={"#fcc419"}
          parcel={{
            shape: splittedParcel.shape,
            name: values?.name + "_" + (index + 2).toString(),
          }}
        />
      )),
    [values.splittedParcels, values?.name],
  );

  const otherParcelsOfCustomer = useMemo(
    () =>
      transformationMode !== POLYGON_TRANSFORMATION_MODE.SPLIT &&
      allParcelsOfCustomer
        ?.filter(
          (parcel) =>
            values.parcelId !== parcel._id &&
            !values.mergedParcelIds.includes(parcel._id),
        )
        .map((parcel) => (
          <MapParcel
            onClick={() =>
              setParcelIdToMerge(
                transformationMode === POLYGON_TRANSFORMATION_MODE.MERGE
                  ? parcel._id
                  : undefined,
              )
            }
            showPolygon
            fillColor={parcelIdToMerge === parcel._id ? "#fcc419" : undefined}
            showLabel={false}
            key={parcel._id}
            parcel={parcel}
          />
        )),
    [
      allParcelsOfCustomer,
      parcelIdToMerge,
      setParcelIdToMerge,
      transformationMode,
      values.mergedParcelIds,
      values.parcelId,
    ],
  );

  return (
    <>
      {parcelMarker}
      {parcelPolygon}
      {splittedParcels}
      {otherParcelsOfCustomer}
    </>
  );
};
